import { useEffect } from 'react';
import Axios from '../../Services/Axios';
import { API_URL } from '../Constants';
import { userAppContext } from '../../Context/Context';
import { DATASETS } from '../Constants';
import Section from './Section';
import { patchItems } from '../Patches/PatchSearchItems';
import { SecondSearchButton } from './SubmitButton';
import { LinksExamples } from './LinksExamples';

const Dropdown = ({ value, setValue, searchItems, setSearchItems, onDropdownToggle }) => {
  const baseEndpoint = `${API_URL}api/search`;
  const { authorization, isAuthenticated } = userAppContext();

  useEffect(() => {
    // Notify that dropdown is open
    onDropdownToggle(true);
    // Cleanup function to notify when dropdown is closed
    return () => onDropdownToggle(false);
  }, [value, onDropdownToggle]);

  return (
    <Axios
      url={baseEndpoint}
      params={{
        pattern: value,
        ...(!isAuthenticated && { skey: 'unauth' }),
      }}
      authorization={authorization}
    >
      {({ data, loading, error }) => {
        if (loading) {
          return (
            <div
              id="react-autowhatever-1"
              className="react-dropdown-container"
              data-testid="dropdownDiv" // Add react testing id
            >
              <div className="section-dropdown">
                <span>Loading...</span>
              </div>
              <div>
                <LinksExamples searchItems={searchItems} setSearchItems={setSearchItems} />
              </div>
            </div>
          );
        }

        if (error) {
          // console.error(error);
          if (error?.includes('403')) {
            return (
              <div
                id="react-autowhatever-1"
                className="react-dropdown-container"
                data-testid="dropdownDiv" // Add react testing id
              >
                <div className="section-dropdown">
                  <span>Session expired. Please refresh page</span>
                </div>
              </div>
            );
          }
          return (
            <div
              id="react-autowhatever-1"
              className="react-dropdown-container"
              data-testid="dropdownDiv" // Add react testing id
            >
              <div className="section-dropdown">
                <span>Error: {error}</span>
              </div>
            </div>
          );
        }

        if (
          data == undefined ||
          (data?.diseases?.length === 0 &&
            data?.genes?.length === 0 &&
            data?.variants?.length === 0 &&
            data?.chemicals?.length === 0)
        ) {
          return (
            <>
              <div
                id="react-autowhatever-1"
                className="react-dropdown-container"
                data-testid="dropdownDiv" // Add react testing id
              >
                <div className="section-dropdown">
                  <a>No results found</a>
                </div>
                <div>
                  <LinksExamples searchItems={searchItems} setSearchItems={setSearchItems} />
                </div>
              </div>
            </>
          );
        }

        // else data is available, render sections:
        const { diseases, genes, variants, chemicals } = data;
        const sections = [];
        // filter sections based on existing searchItems type
        if (searchItems.length == 0) {
          // no filter
          if (diseases.length > 0) {
            sections.push({ title: DATASETS.DISEASES, data: diseases });
          }
          if (genes.length > 0) {
            sections.push({ title: DATASETS.GENES, data: genes });
          }
          if (variants.length > 0) {
            sections.push({ title: DATASETS.VARIANTS, data: variants });
          }
          if (chemicals.length > 0) {
            sections.push({ title: DATASETS.CHEMICALS, data: chemicals });
          }
        } else if (Object.keys(searchItems[0]).includes('diseaseID')) {
          sections.push({ title: DATASETS.DISEASES, data: diseases });
        } else if (Object.keys(searchItems[0]).includes('geneID')) {
          sections.push({ title: DATASETS.GENES, data: genes });
        } else if (Object.keys(searchItems[0]).includes('strID')) {
          sections.push({ title: DATASETS.VARIANTS, data: variants });
        } else if (Object.keys(searchItems[0]).includes('chemUMLSCUI')) {
          sections.push({ title: DATASETS.CHEMICALS, data: chemicals });
        }

        let render = data ? (
          <>
            <div
              id="react-autowhatever-1"
              className="react-dropdown-container"
              data-testid="dropdownDiv" // Add react testing id
            >
              <div className="section-dropdown">
                {sections.map(subSection => {
                  return (
                    <Section
                      key={subSection.title + '_key'}
                      data={subSection.data}
                      title={subSection.title}
                    />
                  );
                })}
              </div>
              <div className="botonCab">
                {searchItems.length >= 1 ? (
                  <>
                    <button
                      className="BtnAccion"
                      id="selectAll"
                      onClick={evt => {
                        setSearchItems(prev => {
                          // add new items to searchItems if not already present
                          const newItems = patchItems(sections[0].data);
                          return prev.concat(
                            newItems.filter(item => !prev.some(prevItem => prevItem.id === item.id)),
                          );
                        });
                      }}
                    >
                      Select All
                    </button>
                    <SecondSearchButton setValue={setValue} className={'BtnAccion'} id={'search'} />
                    <button
                      className="BtnAccion"
                      id="clearAll"
                      onClick={evt => {
                        setSearchItems([]);
                      }}
                    >
                      Clear All
                    </button>
                    <div></div>
                  </>
                ) : (
                  <button className="BtnAccion" id="close" onClick={evt => setValue('')}>
                    Close
                  </button>
                )}
              </div>
              {searchItems.length === 0 && (
                <LinksExamples searchItems={searchItems} setSearchItems={setSearchItems} />
              )}
            </div>
          </>
        ) : (
          <></>
        );
        return render;
      }}
    </Axios>
  );
};

export default Dropdown;
