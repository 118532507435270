const posts = [
  {
    title: 'Genetics In Drug Discovery: The Key To Clinical Trial Success?',
    postName: 'The-Key-To-Clinical-Trial-Success',
    category: ['Product'],
    tags: [
      'am-i-eligible-for-the-disgenet-academic-license',
      'disgenet-academic-license',
      'disgenet-academic-plans',
      'disgenet-for-academics',
      'disgenet-com',
      'free-access-disgenet',
      'gene-disease-database',
      'Disease-Genomics-Research',
    ],
    date: 'August 28, 2024',
    image: '../../static/images/blog/august28-post4.jpg',
    description:
      'By leveraging human genetics to identify drug targets with a proven link to disease mechanisms, the probability of clinical trial success greatly increases [1]. Only 10% of drugs that enter phase 1 clinical trials eventually make it to market [2], primarily due to issues related to efficacy or safety that arise later on [...]',
    content: '',
  },
  {
    title: 'DISGENET Academic License: Eligibility, Application & Free Access',
    postName: 'disgenet-academic-license-guide',
    category: ['Product'],
    tags: [
      'am-i-eligible-for-the-disgenet-academic-license',
      'disgenet-academic-license',
      'disgenet-academic-plans',
      'disgenet-for-academics',
      'disgenet-com',
      'free-access-disgenet',
      'gene-disease-database',
    ],
    date: 'August 2, 2024',
    image: '../../static/images/blog/image-post1-1024x613.png',
    description:
      'DISGENET is the most extensive and reliable gene-disease association network, providing the latest and most relevant data in the field. It was created by MedBioinformatics, and as an academic or not-for-profit researcher, you can access DISGENET’s core biomedical data at no cost. For deeper insights,  [...]',
    content: '',
  },
  {
    title: 'How DISGENET Uses NLP To Accelerate Drug Development',
    postName: 'disgenet-nlp-to-accelerate-drug-development',
    category: ['Article', 'Product'],
    tags: ['Bioinformatics'],
    date: 'July 19, 2024',
    image: '../../static/images/blog/July-2024-list-.jpg',
    description:
      'With DISGENET, you can easily unlock new insights and greatly accelerate your drug development journey. Every year, more than 1 million papers enter PubMed in the biomedical field [1]. While medical knowledge doubles every 73 days and is increasing at an exponential rate with no evidence of slowing [2], drug  [...]',
    content: '',
  },
  // ---
  {
    title: 'DISGENET: The Next Chapter For Disease Genomics Research',
    postName: 'The-Next-Chapter-For-Disease-Genomics-Research',
    category: ['Product'],
    tags: [
      'am-i-eligible-for-the-disgenet-academic-license',
      'disgenet-academic-license',
      'disgenet-academic-plans',
      'disgenet-for-academics',
      'disgenet-com',
      'free-access-disgenet',
      'gene-disease-database',
      'Disease-Genomics-Research',
    ],
    date: 'June 19, 2024',
    image: '../../static/images/blog/June-19-2024-post3.png',
    description:
      'From today, DisGeNET.org and DISGENET plus will become one unified platform found at disgenet.com. DisGeNET has become a cornerstone for bioinformatics research since starting in 2010. As a small team, we have dedicated 15 years to this valuable resource and we are committed to continuously improving   [...]',
    content: '',
  },
];

function generateContent(post) {
  const catHtml = post.category
    .map(category => `<a href="category/${category}" target="_blank">${category.replace(/-/g, ' ')}</a>`)
    .join(', ');

  switch (post.postName) {
    case 'disgenet-academic-license-guide':
      return `
    <div>
      <h1 class="blog-title">DISGENET Academic License: Eligibility, Application & Free Access</h1>
        <p class="blog-date">Posted on <strong>August 2, 2024</strong></p>
        <div class="blog-img">
        <img src="../../static/images/blog/Image-post2-8.png" alt="DISGENET Academic License" class="blog-image" />
        </div>
        <p class="blog-paragraph">
        DISGENET is the most extensive and reliable gene-disease association network, providing the latest and most relevant data in the field. It was created by <a href="https://www.medbioinformatics.com" class="blog-link">MedBioinformatics</a>, and as an academic or
        not-for-profit researcher, you can access DISGENET’s core biomedical data at no cost. For deeper
        insights, we offer additional options utilizing state-of-the-art NLP technology.
        </p>
        <p class="blog-paragraph">In this blog, we cover everything you need to know about DISGENET as an academic or not-for-profit researcher.</p>
    </div>
    <div>
      <h2 class="blog-subtitle">DISGENET Subscription Plans for Academics</h2>
        <p class="blog-paragraph">DISGENET offers several plans to cater to different user needs. For academic and not-for-profit researchers, the <a href="/academic-apply" class="blog-link">Free Academic</a> plan is the primary option, designed to support key research activities by providing you access to fundamental bioinformatics information, including well-known disease genes and variants.</p>
        <p class="blog-paragraph">However, if you require additional data and features, DISGENET also provides the <strong>Standard</strong> and <strong>Advanced</strong> plans.</p>
        <p class="blog-paragraph">This guide will primarily focus on the Free Academic plan and its benefits. We will also briefly outline the Standard and Advanced plans for your reference.</p>
    </div>
    <div>
      <h2 class="blog-subtitle">Eligibility for the DISGENET Free Academic License</h2>
      <h5 class="blog-sub-subtitle">To qualify for the DISGENET Free Academic License, you must meet the following criteria:</h5>
        <ul class="blog-list">
          <li class="blog-paragraph"><strong>Affiliation:</strong> Be affiliated with a recognized academic institution (university, college, research institute). You must use your institutional email address when signing up.</li>
          <li class="blog-paragraph"><strong>Non-commercial Use:</strong> Use DISGENET for non-commercial academic research purposes. For more information on what is considered commercial or non-commercial, consult our FAQs or take a look at the infographic below.</li>
        </ul>
        <p class="blog-paragraph">If you meet these requirements, you can proceed with the application process.</p>
        <img src="../../static/images/faqbanner.png" alt="DISGENET Academic License" class="blog-image"  style="display: block; margin-left: auto; margin-right: auto; width: 55%; height: auto;"/>
        <br />
    </div>
    <div>
      <h2 class="blog-subtitle">Application Process for the Free Academic License</h2>
      <h5 class="blog-sub-subtitle">To apply for a DISGENET Free Academic License, please follow these steps:</h5>
        <ul class="blog-list">
          <li class="blog-paragraph"><strong>Complete the Application:</strong> Fill out the Academic License application form on our website with accurate personal details, institutional information, and supporting links (like your LinkedIn profile). Our team will review your application manually.</li>
          <li class="blog-paragraph"><strong>Review:</strong> Double-check your application for accuracy and completeness before submitting.</li>
          <li class="blog-paragraph"><strong>Verify Your Email:</strong> Confirm your email address by clicking the link in our verification email. This step is essential and expires in 24 hours.</li>
        </ul> 
    <div style="text-align: center; margin: 40px; padding: 25px; border: 2px solid #ccc; border-radius: 5px; max-width: 800px; margin-left: auto; margin-right: auto;">
        <p style="font-style: italic;">“DISGENET has been a game-changer in our research endeavors. We wholeheartedly recommend DISGENET to other researchers and colleagues working in similar domains.”</p>
        <p>– <strong>Maria F. Anglero, Research Assistant, Pennsylvania State University</strong></p>
    </div>
    </div>
    <div>
      <h2 class="blog-subtitle">How long does it take to obtain your Free Academic License?</h2>
        <p class="blog-paragraph">You can expect to receive a notification within 7 days, via email, confirming whether your application for a Free Academic License has been approved or denied.</p>
        <p class="blog-paragraph">In order to get started right away, we’ll provide you with instant access to a 7-day free trial* of our Advanced plan, allowing you to explore DISGENET’s text-mined data and other features before your license is confirmed.</p>
        <p class="blog-paragraph">*Please note that the Advanced free trial has limitations, such as displaying only the top 30 results.</p>
    </div>
     <div>
      <h2 class="blog-subtitle">Benefits of the Free Academic License</h2>
        <p class="blog-paragraph">The DISGENET Free Academic License provides you with a valuable tool to advance your research. Some of the key benefits include:</p>
       <ul class="blog-list">
          <li class="blog-paragraph"><strong>Access to Comprehensive Gene-Disease Information:</strong> Explore a vast database of curated gene-disease associations, enabling you to identify potential research targets and uncover new insights.</li>
          <li class="blog-paragraph"><strong>Data Analysis and Visualization:</strong> Utilize analytics features, API integration, R package compatibility, and Cytoscape app for in-depth data exploration and visualization.</li>
          <li class="blog-paragraph"><strong>Free Access to Updates:</strong> Benefit from quarterly updates to the database, ensuring you have access to the latest gene-disease information.</li>
        </ul>
         <div style="text-align: center; margin: 40px; padding: 25px; border: 2px solid #ccc; border-radius: 5px; max-width: 800px; margin-left: auto; margin-right: auto;">
        <p style="font-style: italic;">“DISGENET has played a significant role in our efforts to identify genetic variants associated with autism from WES data. It helped us focus our search on genes linked to neurodevelopmental disorders and autism comorbidities, prioritize variants using GDA scores, and integrate them into our scoring system”</p>
        <p>– <strong>Nadav Golden, Azrieli National Centre for Autism & Neurodevelopment Research, BGU</strong></p>
    </div>
    </div>
    <div>
      <h2 class="blog-subtitle">Upgrading Your DISGENET Plan</h2>
        <p class="blog-paragraph">While the <a href="/academic-apply" class="blog-link">Free Academic License</a> provides a fantastic starting point for your research, with access to a wealth of curated data on genes and disease variants, you may encounter projects requiring even more.</p>
        <p class="blog-paragraph">The <a href="/plans" class="blog-link">Standard and Advanced</a> plans offer additional features and data that may be necessary for certain research projects.</p>
    </div>
    <div>
      <h5 class="blog-sub-subtitle">DISGENET Standard & Advanced plans offer access to:</h5>
        <ul class="blog-list">
          <li class="blog-paragraph">Text-mined data</li>
          <li class="blog-paragraph">Loss of function/gain of function annotations</li>
          <li class="blog-paragraph">Clinical trial annotations</li>
          <li class="blog-paragraph">Drugs and chemical data</li>
        </ul>
        <p class="blog-paragraph">If your research demands exceed the capabilities of the Free Academic plan, consider upgrading to a paid plan. We have pricing plans specifically adapted to academics.</p>
        <img src="../../static/images/blog/Academic-plans-graphic-blog.jpg" alt="DISGENET Academic License" class="blog-image" style="display: block; margin-left: auto; margin-right: auto; width: 55%; height: auto;"/>
        <br />
        <p class="blog-paragraph">To explore your upgrade options or request a quote, please contact us <strong>info@disgenet.com</strong></p>
        
    </div>
    <div>
      <h2 class="blog-subtitle">Apply for your Free Academic License</h2>
        <p class="blog-paragraph">If you’re affiliated with a recognized academic institution and conducting non-commercial research, we encourage you to apply for the DISGENET Free Academic License today. Head to our website now and <a href="/academic-apply">fill in your academic application.</a></p>
        <h5 class="blog-sub-subtitle">Master the Platform: Watch Our 5-Minute Tutorials</h5>
        <ul class="blog-list">
        <strong>
          <li class="blog-paragraph"><a href="https://www.youtube.com/watch?v=Q4GdJ1Aym3o" target="_blank">Get Started Searching | DISGENET Summary & Evidence Tables</a></li>
          <li class="blog-paragraph"><a href="https://www.youtube.com/watch?v=MC4TzstfiPE" target="_blank">What Data is in DISGENET? [+ Customization & Visualization Options]</a></li>
          <li class="blog-paragraph"><a href="https://www.youtube.com/watch?v=vy4AppkFTus" target="_blank">How to Filter for Relevant Data: Filtering Options & Search Tips</a></li>
        </strong>
        </ul>
    </div>
    <div>
      <h2 class="blog-subtitle"></h2>
      <p class="blog-paragraph"><strong>Previous: <a href="disgenet-nlp-to-accelerate-drug-development">DISGENET NLP to Accelerate Drug Development</a></strong></p>
    </div>
    `;

    case 'disgenet-nlp-to-accelerate-drug-development':
      return `
   <div>
        <h1 class="blog-title">${post.title}</h1>
        <p class="blog-date">Posted on <strong>${post.date}</strong></p>
        <div class="blog-img">
          <img src="${post.image}" alt="${post.title}" class="blog-image" />
        </div>
        <div>
          <h2 class="blog-subtitle">With DISGENET, you can easily unlock new insights and greatly accelerate your drug development journey.</h2>
          <p class="blog-paragraph">Every year, more than 1 million papers enter PubMed in the biomedical field [1]. While medical knowledge doubles every 73 days and is increasing at an exponential rate with no evidence of slowing [2], drug discovery and development remain a time-consuming and costly process, characterized by high failure rates and great uncertainty [3]. One of the biggest challenges facing drug discovery companies is that 80% of biomedical data is currently unstructured within text documents [4]. This data flood has become unmanageable for human readers and has set the stage for AI tools such as Natural Language Processing (NLP) to assist with improving drug discovery and drug development.</p>
        </div>
        <div>
          <h2 class="blog-subtitle">What is NLP?</h2>
          <p class="blog-paragraph">NLP is an AI technique to process and analyze texts automatically. NLP enables the transition from time-consuming, manual, and isolated curation of natural language data to automated, large-scale, standardized processes for text analysis [4]. In essence, NLP reads an extensive amount of documents for the users. It identifies and extracts relevant facts and relationships in a structured format suitable for fast review and analysis. NLP connects facts to synthesize knowledge and generate actionable insights.</p>
        </div>
         <div>
          <h2 class="blog-subtitle">How does DISGENET integrate NLP?</h2>
          <p class="blog-paragraph">DISGENET is a platform populated with top-quality data generated with our state-of-the-art NLP technologies (F-score 92%) especially tuned to extract fine-grained information from biomedical texts. Our NLP tool relies on community-driven ontologies and terminologies that we carefully curated to standardize highly relevant data for drug discovery. It combines language models to detect mentions of diseases, genes, variants and chemicals and their semantic relationships. The high standardization of our data allows for seamless integration with other datasets, ensuring complete interoperability with your internal data.</p>
        </div>
       <div>
       <div class="blog-paragraph">
          <img src="../../static/images/blog/july-2024-list-1.jpg" alt="${post.title}" class="blog-image" style="display: block; margin-left: auto; margin-right: auto; width: 70%; height: auto;" />
         <p style="max-width: 70%; margin-left: auto; margin-right: auto; font-style: italic; margin-top: 10px;  margin-bottom: 40px;">
          How DISGENET’s NLP tool detects mentions of diseases, genes, variants and chemicals and their relationships.
        </p>
          </div>
        <p class="blog-paragraph">DISGENET leverages NLP to continually expand its pool of high-quality data with each quarterly update, creating a proprietary dataset spanning human and animal models (i.e., rat, mouse, fly, dog, and zebrafish among others). As a result, DISGENET structures up to date information with an ample coverage of diseases, phenotypes, genes, and variants which makes our platform an essential tool for drug development. DISGENET currently contains a corpus of over 1.5 million publications supporting genotype-phenotype associations, derived from analyzing over 30 million publications with NLP. Accessing DISGENET is equivalent to reading millions of articles to fully unlock the data.</p>
        <p class="blog-paragraph">For example, curated data sources report only 10 genes associated to Duchenne muscular dystrophy, while NLP data from DISGENET provides evidence for an additional 502 genes.</p>
        <div class="blog-paragraph">
          <img src="../../static/images/blog/july-2024-list-2.jpg" alt="${post.title}" class="blog-image" style="display: block; margin-left: auto; margin-right: auto; width: 70%; height: auto;" />
          <p style="max-width: 70%; margin-left: auto; margin-right: auto; font-style: italic; margin-top: 10px;  margin-bottom: 40px;">
          The increase of data using NLP versus curated sources
        </p>
        </div>
        <div>
        <h5 class="blog-sub-subtitle">DISGENET v24.2 gathers over 50 million associations connecting:</h5>
        <ul class="blog-list">
        <strong>
          <li class="blog-paragraph">700,000+ disease variants</li>
          <li class="blog-paragraph">40,000+ diseases & traits</li>
          <li class="blog-paragraph">32,000+ disease genes</li>
        </strong>
        </ul>
        </br>
        </div>
         <div>
          <h2 class="blog-subtitle">How can DISGENET help you with your drug development research?</h2>
           <h5 class="blog-sub-subtitle">DISGENET and target identification in drug discovery</h5>
          <p class="blog-paragraph">Ninety percent of drugs entering phase 1 clinical trials never reach the market due to efficacy or safety issues uncovered later on. These clinical failures highlight the great potential for improvement in selecting relevant targets. Drugs that modulate targets with supporting GWAS evidence are twice as likely to be approved [5]. Additionally, systematic analysis of FDA drug approvals showed that drugs with human genetics support were 2-5 fold more likely to lead to an improved therapy [6]. As a result, genetic evidence linking potential therapeutic targets and diseases is crucial for identifying and prioritizing targets leading to safe and effective drugs.</p>
        <p class="blog-paragraph">With DISGENET, you can:</p>
        <ul class="blog-list" style="margin-left: 20px;">
          <li class="blog-paragraph">access to the genetic evidence that associates targets and diseases</li>
          <li class="blog-paragraph">identify the targets with genetic evidence and their association types with a specific disease</li>
          <li class="blog-paragraph">retrieve evidence for these targets from diverse sources and animal models</li>
          <li class="blog-paragraph">identify rare genetic variations of the targets</li>
          <li class="blog-paragraph">identify new targets by broadening the disease search using our proprietary ontology</li>
        </ul>
          <h5 class="blog-sub-subtitle">DISGENET and safety assessment in drug development</h5>
          <p class="blog-paragraph">Safety issues are a primary reason for drug candidate failure. However, genetic evidence can provide insights into target safety liabilities upfront. While genetic evidence supports many adverse events, the absence of negative consequences of gene loss of function unveils valuable insights into drug tolerability [7].</p>
          <p class="blog-paragraph">With DISGENET, you can:</p>
          <ul class="blog-list" style="margin-left: 20px;">
            <li class="blog-paragraph">identify genetic variants of targets associated with adverse drug events or unwanted phenotypes</li>
            <li class="blog-paragraph">identify the possible risks in modulating a specific target</li>
            <li class="blog-paragraph">identify unwanted phenotypes associated with a target in various animal models</li>
          </ul>

           <h5 class="blog-sub-subtitle">DISGENET and drug repurposing</h5>
          <p class="blog-paragraph">Drug development is filled with uncertainty, marked by prolonged timelines to market and exorbitant costs. In that landscape, drug repurposing emerges as a great alternative to avoid the challenges associated with novel compounds development. Approved drugs have already passed all phases of clinical trials and have an established safety profile. Thus, their repurposing represents a major opportunity to rapidly access the market with reduced R&D risks, time, and costs [8].</p>
          <p class="blog-paragraph">With DISGENET, you can:</p>
          <ul class="blog-list" style="margin-left: 20px;">
            <li class="blog-paragraph">identify diseases’ similarity based on their associated genes or semantic similarity</li>
            <li class="blog-paragraph">identify new indications for the targets of an existing drug</li>
          </ul>
         </div>
         </br>
         <div>
          <h2 class="blog-subtitle">DISGENET: proprietary scores and beyond for accelerated drug development</h2>
          <p class="blog-paragraph">DISGENET provides highly relevant scores for drug discovery, allowing for easy navigation and prioritization of the data. We developed two scores to highlight the strength of gene-disease <a href="/About#metrics" class="blog-link">(GDA score)</a>, and variant-disease associations <a href="/About#metrics" class="blog-link">(VDA score)</a>. These scores range from 0 to 1 according to their level of evidence. They rely on the level of curation, the model organisms, and the number of publications reporting the association. Additionally, associations can be further characterized using association types. <a href="/About#assoctypeont" class="blog-link">Our association type ontology</a> includes drug response variants, causal mutation, or biomarkers among many others unveiling critical information for drug development.</p>
          <p class="blog-paragraph">Our NLP tool automatically highlights the key concepts in the relevant sentences of published abstracts. Gene, variant, disease, and chemical concepts, as well as any negation markers, are clearly displayed for your review.</p>
         <div class="blog-paragraph">
          <img src="../../static/images/blog/list2-2.jpg" alt="${post.title}" class="blog-image" style="display: block; margin-left: auto; margin-right: auto; width: 70%; height: auto;" />
          <p style="max-width: 70%; margin-left: auto; margin-right: auto; font-style: italic; margin-top: 10px;  margin-bottom: 40px;">
          Using NLP, DISGENET extracts and identifies key concepts to enable faster research
          </p>
           <p class="blog-paragraph">By applying our first and last years of publication filters, you can quickly evaluate years of research, visualize publication trends for a specific association, or directly access the latest discoveries. DISGENET also annotates gain and loss of function mutations associated with disease phenotypes. As a result, you can accurately estimate the potential effects of targeting the related genes.</p>
           <p class="blog-paragraph">Additionally, we created two indexes to analyze gene-disease associations. The <a href="/About#specificity" class="blog-link">Disease Specificity Index </a>(DSI) evaluates whether a gene or variant is linked to multiple diseases or only a few. The  <a href="/About#pleiotropy" class="blog-link">Disease Pleiotropy Index </a>(DPI) determines whether the associated diseases are similar or span different MeSH disease classes. Thus, target prioritization is enhanced, allowing for more effective and focused therapeutic strategies.</p>
           <p class="blog-paragraph">Finally, our high-quality data is the perfect input to train AI/ML tools and fill in the vastly incomplete gene-disease association network to bring to light new therapeutic opportunities in drug discovery [9].</p>
          </div>
         </div>
      <div>
        <h2 class="blog-subtitle">DISGENET, with the power of NLP, will enable you to uncover critical information, significantly speeding up drug development.</h2>
        <h5 class="blog-sub-subtitle">Sign up <a href="/free-trial-apply" class="blog-link"><u>here</u></a> for a free 7-day trial of DISGENET Advanced</h5>
        <h5 class="blog-sub-subtitle">Request a Free Demo <a href="/free-trial-apply" class="blog-link"><u>here</u></a></h5>
        <p class="blog-paragraph">Contact us at <strong>info@disgenet.com</strong></p>
      </div>
      <div>
         <h2 class="blog-subtitle">References</h2>
          <ol>
            <li>[1] Landhuis, E. (2016) Scientific literature: Information overload. Nature, 535, 457–8.  <a href="https://doi.org/10.1038/nj7612-457a">https://doi.org/10.1038/nj7612-457a</a></li>
            <li>[2] Siegel, M.G., Rossi, M.J. and Lubowitz, J.H. (2024) Artificial Intelligence and Machine Learning May Resolve Health Care Information Overload. Arthroscopy: The Journal of Arthroscopic & Related Surgery, 40, 1721–3. <a href="https://doi.org/10.1016/j.arthro.2024.01.007">https://doi.org/10.1016/j.arthro.2024.01.007</a></li>
            <li>[3] Liu, Z., Roberts, R.A., Lal-Nag, M., Chen, X., Huang, R. and Tong, W. (2021) AI-based language models powering drug discovery and development. Drug Discovery Today, 26, 2593–607. <a href="https://doi.org/10.1016/j.drudis.2021.06.009">https://doi.org/10.1016/j.drudis.2021.06.009</a></li>
            <li>[4] Bhatnagar, R., Sardar, S., Beheshti, M. and Podichetty, J.T. (2022) How can natural language processing help model informed drug development?: a review. JAMIA Open, 5, ooac043. <a href="https://doi.org/10.1093/jamiaopen/ooac043">https://doi.org/10.1093/jamiaopen/ooac043</a></li>
            <li>[5] Cook, D., Brown, D., Alexander, R., March, R., Morgan, P., Satterthwaite, G. et al. (2014) Lessons learned from the fate of AstraZeneca’s drug pipeline: a five-dimensional framework. Nature Reviews Drug Discovery, 13, 419–31. <a href="https://doi.org/10.1038/nrd4309">https://doi.org/10.1038/nrd4309</a></li>
            <li>[6] Nelson, M.R., Johnson, T., Warren, L., Hughes, A.R., Chissoe, S.L., Xu, C.-F. et al. (2016) The genetics of drug efficacy: opportunities and challenges. Nature Reviews Genetics, 17, 197–206. <a href="https://doi.org/10.1038/nrg.2016.12">https://doi.org/10.1038/nrg.2016.12</a></li>
            <li>[7] Minikel, E.V. and Nelson, M.R. (2023) Human genetic evidence enriched for side effects of approved drugs [Internet]. <a href="https://doi.org/10.1101/2023.12.12.23299869">https://doi.org/10.1101/2023.12.12.23299869</a></li>
            <li>[8] Nosengo, N. (2016) Can you teach old drugs new tricks? Nature, 534, 314–6. <a href="https://doi.org/10.1038/534314a">https://doi.org/10.1038/534314a</a></li>
            <li>[9] Bonner, S., Barrett, I.P., Ye, C., Swiers, R., Engkvist, O., Bender, A. et al. (2022) A review of biomedical datasets relating to drug discovery: a knowledge graph perspective. Briefings in Bioinformatics, 23, bbac404. <a href="https://doi.org/10.1093/bib/bbac404">https://doi.org/10.1093/bib/bbac404</a></li>
          </ol>
      </div>
      </div>
    `;

    case 'The-Next-Chapter-For-Disease-Genomics-Research':
      return `
   <div>
   
    <h1 class="blog-title">${post.title}</h1>
        <p class="blog-date">Posted on <strong>${post.date}</strong></p>
        <div class="blog-img">
          <img src="${post.image}" alt="${post.title}" class="blog-image" />
        </div>
        <div>
          <p class="blog-paragraph"><strong>From today, DisGeNET.org and DISGENET plus will become one unified platform found at disgenet.com.</strong></p>
          <p class="blog-paragraph">DisGeNET has become a cornerstone for bioinformatics research since starting in 2010. As a small team, we have dedicated 15 years to this valuable resource and we are committed to continuously improving the platform for you, our users.</p>
          <p class="blog-paragraph">As you can imagine, the scope and usage of our data have grown massively since we began. Consequently, maintaining the service of DisGeNET at the level we aspire to has become increasingly challenging in recent years. Securing the necessary funding for continuous improvement and timely updates has proven to be a formidable task. Therefore, we have looked for alternative ways to secure the future of DisGeNET.</p>
          <p class="blog-paragraph">In 2020, we established <a href="https://www.medbioinformatics.com/" target="_blank" class="blog-link">MedBioinformatics</a>, a spin-off company dedicated to providing DisGeNET to commercial and for-profit users – what you may know now as DISGENET plus.</p>
          <p class="blog-paragraph">Meanwhile, DisGeNET.org has continued to serve our academic community well, earning high prestige within the scientific community, with over 5,500 citations in academic publications. Our user base has grown to over 130,000, a testament to the platform’s importance and impact on research worldwide.</p>
          <h5 class="blog-sub-subtitle">A New Chapter – A Unified Platform</h5>
          <p class="blog-paragraph">In order to keep releasing the most accurate, high-quality data, to sustain this valuable service, and to ensure DisGeNET’s continued growth, further change is needed.</p>
          <p class="blog-paragraph">From today, DisGeNET.org and DISGENET plus will become one unified platform at  <a href="https://www.disgenet.com/" target="_blank" class="blog-link">disgenet.com</a>. This platform is designed to cater to the needs of both academic and commercial users, ensuring the best possible experience for everyone.</p>
          <p class="blog-paragraph">We have also undergone a brand refresh to align our image with this important new chapter.</p>
          <h5 class="blog-sub-subtitle">Transparency and Benefits for Academia</h5>
          <p class="blog-paragraph">Following the merging of our platforms, our valued academic users will need to migrate to disgenet.com to continue accessing the wealth of data available. The new DISGENET will provide free access to a large collection of gene and variant-disease associations for academic purposes, including well-known disease genes and variants. This data will be readily available to not-for-profit and academic users who obtain a free Academic License through a simple application process.</p>
           <p class="blog-paragraph">For academic researchers requiring programmatic access to the full database, we’ve introduced competitive subscription fees designed specifically for academic institutions.</p>
         <h5 class="blog-sub-subtitle">Our Commitment to Open Science</h5>
          <p class="blog-paragraph">Scientific progress relies on a foundation of prior research findings. This is especially true in data-driven fields like biomedicine. Our company actively supports this progress by providing open data resources and open-source software. This commitment fosters a vibrant research community that contributes to the continuous improvement of scientific knowledge.</p>
          <p class="blog-paragraph">The benefits of this approach ultimately translate into a more powerful and valuable DISGENET for commercial research too.</p>
           <h5 class="blog-sub-subtitle">Unparalleled Disease Genomics Data</h5>
          <p class="blog-paragraph">DISGENET will house a wealth of exciting features for both academic and commercial users, powered by our commitment to data quality and comprehensiveness:</p>
          <ul class="blog-list">
            <li class="blog-paragraph"><strong>Unmatched Accuracy:</strong> Leverage the power of our state-of-the-art F-score with 92% accuracy. </li> 
            <li class="blog-paragraph"><strong>Quarterly updates</strong> with the latest scientific findings.</li>
            <li class="blog-paragraph"><strong>Most Extensive Database:</strong> Access one of the world’s most comprehensive databases of gene-disease relationships available, avoiding data silos and enabling holistic research approaches.</li>
            <li class="blog-paragraph"><strong>Data Transparency:</strong> We believe in full platform transparency. DISGENET clearly discloses full statistics about database contents, features and performance metrics.</li>
            <li class="blog-paragraph"><strong>Text Mining and Curation:</strong> The platform leverages both text-mining and curated data integration, ensuring the most comprehensive and reliable information.</li>
            <li class="blog-paragraph"><strong>New features:</strong> Expect to find new data types and annotations, as well as a web interface, REST API, and R package with new functionalities to leverage this enriched data. </li>
            <li class="blog-paragraph"><strong>Application to multiple research questions: </strong>Our database facilitates a wide range of applications in drug R&D, precision medicine, and other areas as illustrated by the different topics covered by scientists citing DISGENET in their publications.</li>
          </ul>
         <h5 class="blog-sub-subtitle">Our Mission Continues</h5>
          <p class="blog-paragraph">We remain committed to our core mission: providing actionable and trustworthy data about the relationship between genes and diseases to accelerate therapeutics research and development, thus improving and saving people’s lives. This evolution of DISGENET ensures continued growth and empowers researchers across the board to continue to make groundbreaking discoveries with real-world impact.</p>
          <h5 class="blog-sub-subtitle">Building a Healthier Tomorrow, Together</h5>
          <p class="blog-paragraph">DISGENET is committed to providing the most comprehensive and reliable data to fuel advancements in research, ultimately leading to improved health outcomes. This aligns perfectly with our new tagline, Data For Tomorrow’s Health.</p>
          <p class="blog-paragraph">We want to thank you for your continued support and remind you that our team is here to help you through this exciting evolution.</p>
          <p class="blog-paragraph">Your feedback, as part of a large community of users, is invaluable in helping us build a better resource. Together, through fostering open science and collaboration, we can ensure <a href="https://www.disgenet.com/" target="_blank" class="blog-link">disgenet.com</a> remains a valuable resource for all and contributes to building a healthier tomorrow.</p>
          <p class="blog-paragraph">Welcome to the future of DISGENET.</p> 
        </div>
   
   </div>
    `;

    case 'The-Key-To-Clinical-Trial-Success':
      return `
   <div>
        <h1 class="blog-title">${post.title}</h1>
        <p class="blog-date">Posted on <strong>${post.date}</strong></p>
        <div class="blog-img">
            <img src="${post.image}" alt="${post.title}" class="blog-image" />
        </div>
        <div>
            <h3 class="blog-subtitle">By leveraging human genetics to identify drug targets with a proven link to disease mechanisms, the probability of clinical trial success greatly increases [1].</h3>
            <p class="blog-paragraph">Only 10% of drugs that enter phase 1 clinical trials eventually make it to market [2], primarily due to issues related to efficacy or safety that arise later on. These frequent clinical failures highlight the significant opportunity for enhancing target selection and improving clinical outcomes. Drugs that target mechanisms backed by GWAS evidence are twice as likely to receive approval [1]. Furthermore, a comprehensive review of FDA drug approvals revealed that drugs supported by human genetic data are 2 to 5 times more likely to result in successful therapies [3].</p>
        </div>
        <div>
            <h3 class="blog-subtitle">Why is human genetic data essential for improving target selection?</h3>
            <p class="blog-paragraph">The development of effective drugs is often hindered by the challenge of selecting the right target. To increase the chances of success, it’s important to base target selection on a deep understanding of disease mechanisms. Genetic data can provide valuable insights into the mechanisms underlying diseases, establishing a causal link between the gene product’s function and the disease phenotype. By understanding this link, you can confidently select the right target and develop drugs that modulate it, thereby impacting the disease processes. This understanding also aids in choosing the appropriate treatment modality.</p>
        </div>
        <div>
            <h3 class="blog-subtitle">What methods can be used to validate the potential of a drug target beyond human genetics?</h3>
            <p class="blog-paragraph">Apart from human genetics, other experimental data from cellular and animal systems can also inform target selection. These experiments help validate the target’s role in disease pathophysiology, further strengthening the causal link between the gene product’s function and the disease phenotype. A solid understanding of this link leads to therapeutic interventions that address the disease’s underlying mechanisms.</p>
        </div>
        <div>
            <h3 class="blog-subtitle">Finding genetic and experimental data to support drug target selection</h3>
            <p class="blog-paragraph">DISGENET is an extensive and reliable gene-disease database that contains information on the molecular mechanisms of all human diseases for a large catalog of coding and non-coding genes, as well as genomic variants. By using DISGENET, you can search for potential drug targets for any indication.</p>
        </div>
        <div>
            <h5 class="blog-sub-subtitle">Genetic support for FDA-approved drugs 2023</h5>
            <p class="blog-paragraph">In order to illustrate the information DISGENET can provide, we explored the available genetic evidence on the platform for 2023 FDA-approved drugs.</p>
            <p class="blog-paragraph">Our retrospective analysis found that DISGENET supports a connection between drug targets and therapeutic indications for a large majority of 2023 FDA-approved drugs (91%). In fact, the same analysis for 2021 showed that <strong>DISGENET provides genetic evidence for 90% of approved drugs, outperforming</strong> <a href="https://www.nature.com/articles/d41573-023-00158-x" target="_blank">other publicly available platforms, which covered only 66%.</a></p>
        </div>
        <div>
            <h2 class="blog-subtitle"></h2>
            <h5 class="blog-sub-subtitle">Start your <a href="/free-trial-apply" target="_blank">7-day free trial</a> of DISGENET Advanced</h5>
            <h5 class="blog-subtitle"></h5>
        </div>
        <br />
        <div>
            <h3 class="blog-subtitle">Leveraging genetics in drug discovery: Examples from 2023 FDA-approved drugs</h3>
            <p class="blog-paragraph">Targeting the protein coded by a specific gene known to cause a particular disease greatly enhanced the probability of clinical trial success [4]. DISGENET provides a comprehensive list of genes associated with a disease or indication. DISGENET also includes metrics such as the <a href="/About#metrics" target="_blank">Gene Disease Association (GDA) score</a> (ranging from 0 to 1) to prioritize those genes/proteins by disease/indication, and provide several data attributes to assist the user in understanding the role of the gene in the disease.</p>
            <h5 class="blog-sub-subtitle">Skyclarys and Friedreich Ataxia</h5>
            <p class="blog-paragraph">Most cases of Friedreich Ataxia (FA) are due to defects in the FXN gene that lead to depletion of FXN and impairment of antioxidant pathways in the cell. <a href="/search?view=DISEASES&idents=C0016719&source=ALL&tab=GDA" target="_blank">DISGENET contains 185 genes associated with Friedreich Ataxia</a>, the highest scoring association being FXN. Notably, DISGENET ranks NFE2L2, which codes for NRF2, as the second-highest scoring associations with FA.</p>
            <p class="blog-paragraph">NRF2 is the target of Skyclarys (Omaveloxolone), the first approved treatment for this disease. Most of the evidence supporting the association of this protein and FA has been captured using text mining from articles published between 2013 and 2023.</p>
            <p class="blog-paragraph">NRF2 is involved in the regulation of the oxidative stress pathway, one of the mechanisms underlying the disease. DISGENET provides information about the role of the NFE2L2 gene and the pathogenesis of the disease.</p>
            <img src="../../static/images/blog/august28-post4-2.jpg" class="blog-image" style="display: block; margin-left: auto; margin-right: auto; width: 95%; height: auto;" />
            <br />
            <p class="blog-paragraph">This information is also represented in the DISGENET Association Type. In this case, one of the association types for NFE2L2 and FA is “altered expression”, which is in agreement with the observation that <a href="https://pubmed.ncbi.nlm.nih.gov/31640150/" target="_blank">the expression of NRF2 is decreased in skin biopsies in FA patients</a>. NRF2 is also involved in mitochondrial homeostasis, maintenance of GSH levels and neurogenesis.</p>
            <p class="blog-paragraph"><strong>Thus, DISGENET is a key resource for providing mechanistic information on potential gene targets and therefore expediting the selection of relevant drug targets.</strong></p>
            <h5 class="blog-sub-subtitle">Veozah and Hot Flushes</h5>
            <p class="blog-paragraph">Veozah (Fezolinetant), approved for the treatment of Hot flushes, targets the Neuromedin-K receptor encoded by the TACR3 gene. DISGENET contains information on the association between the gene and the indication, with evidence dating back to 2017, including <a href="/search?view=GENES&idents=6870&source=ALL&tab=VDA" target="_blank">publications supporting the role of antagonists of the receptor</a> for ameliorating hot flushes in menopausal women.</p>
            <p class="blog-paragraph">This text-mined data, identified using DISGENET’s state-of-the-art NLP tool, is not present in expert-curated databases such as UniProt or ClinGen. <a href="/search?view=GENES&idents=6870&source=ALL&tab=VDA" target="_blank">Variants in the gene have also been reported as associated with these symptoms</a>. Interestingly, this gene and its variants are also associated with related traits, such as amenorrhea, age at menarche, hypogonadism, and alterations of puberty, underscoring the role of TACR3 in reproductive health.</p>
            <br />
            <img src="../../static/images/blog/TACR3-post4.jpg" class="blog-image" style="display: block; margin-left: auto; margin-right: auto; width: 95%; height: auto;" />
            <br />
        </div>
        <div>
          <h3 class="blog-subtitle">Leveraging genetics in drug discovery: Examples from 2023 FDA-approved drugs<h3>
          <p class="blog-paragraph">Targeting the protein coded by a specific gene known to cause a particular disease greatly enhanced the probability of clinical trial success [4]. DISGENET provides a comprehensive list of genes associated with a disease or indication. DISGENET also includes metrics such as the <a href="/About#metrics" target="_blank">Gene Disease Association (GDA) score</a> (ranging from 0 to 1) to prioritize those genes/proteins by disease/indication, and provide several data attributes to assist the user in understanding the role of the gene in the disease.</p>
           <h5 class="blog-sub-subtitle">Skyclarys and Friedreich Ataxia</h5>
           <p class="blog-paragraph">Most cases of Friedreich Ataxia (FA) are due to defects in the FXN gene that lead to depletion of FXN and impairment of antioxidant pathways in the cell.  <a href="/search?view=DISEASES&idents=C0016719&source=ALL&tab=GDA" target="_blank">DISGENET contains 185 genes associated with Friedreich Ataxia</a>, the highest scoring association being FXN. Notably, DISGENET ranks NFE2L2, which codes for NRF2, as the second-highest scoring associations with FA.</p>
           <p class="blog-paragraph">NRF2 is the target of Skyclarys (Omaveloxolone), the first approved treatment for this disease. Most of the evidence supporting the association of this protein and FA has been captured using text mining from articles published between 2013 and 2023.</p>
           <p class="blog-paragraph">NRF2 is involved in the regulation of the oxidative stress pathway, one of the mechanisms underlying the disease. DISGENET provides information about the role of the NFE2L2 gene and the pathogenesis of the disease.</p>
          <img src="../../static/images/blog/august28-post4-2.jpg" class="blog-image" style="display: block; margin-left: auto; margin-right: auto; width: 95%; height: auto;" />
          </br>
          <p class="blog-paragraph">This information is also represented in the DISGENET Association Type. In this case, one of the association types for NFE2L2 and FA is “altered expression”, which is in agreement with the observation that  <a href="https://pubmed.ncbi.nlm.nih.gov/31640150/" target="_blank">the expression of NRF2 is decreased in skin biopsies in FA patients</a>. NRF2 is also involved in mitochondrial homeostasis, maintenance of GSH levels and neurogenesis.</p>
          <p class="blog-paragraph"><strong>Thus, DISGENET is a key resource for providing mechanistic information on potential gene targets and therefore expediting the selection of relevant drug targets.</strong></p> 
          <h5 class="blog-sub-subtitle">Veozah and Hot Flushes<h5>
          <p class="blog-paragraph">Veozah (Fezolinetant), approved for the treatment of Hot flushes, targets the Neuromedin-K receptor encoded by the TACR3 gene. DISGENET contains information on the association between the gene and the indication, with evidence dating back to 2017, including <a href="/search?view=GENES&idents=6870&source=ALL&tab=VDA" target="_blank">publications supporting the role of antagonists of the receptor</a> for ameliorating hot flushes in menopausal women.</p>
          <p class="blog-paragraph">This text-mined data, identified using DISGENET’s state-of-the-art NLP tool, is not present in expert-curated databases such as UniProt or ClinGen.  <a href="/search?view=GENES&idents=6870&source=ALL&tab=VDA" target="_blank">Variants in the gene have also been reported as associated with these symptoms</a>. Interestingly, this gene and its variants are also associated with related traits, such as amenorrhea, age at menarche, hypogonadism, and alterations of puberty, underscoring the role of TACR3 in reproductive health.</p>
          </br>
          <img src="../../static/images/blog/TACR3-post4.jpg"  class="blog-image" style="display: block; margin-left: auto; margin-right: auto; width: 95%; height: auto;" />
          </br>
        </div>
        <div>
          <h3 class="blog-subtitle">Leveraging genetics in drug discovery using DISGENET<h3>
          <p class="blog-paragraph">The above examples illustrate the valuable information that DISGENET can provide, allowing you to expedite drug target selection programs.</p>
          </br>
          <img src="../../static/images/blog/Speeds-post4.jpg"  class="blog-image" style="display: block; margin-left: auto; margin-right: auto; width: 95%; height: auto;" />
            </br>
          <p class="blog-paragraph">The DISGENET platform offers:</p>
          <ul class="blog-list">
            <li class="blog-paragraph"><strong>The DISGENET GDA score </strong>to identify targets with support from multiple, independent databases.</li>
            <li class="blog-paragraph"><strong>Text-mined data </strong>not present in expert-curated databases.</li>
            <li class="blog-paragraph"><strong>Association type </strong>to inform how the gene is linked to the disease.</li>
            <li class="blog-paragraph"><strong>Supporting sentences </strong>providing an easy way to find the impact of the gene on disease mechanisms.</li>
            <li class="blog-paragraph"><strong>Comprehensive gene-disease profiles </strong>to help identify potential therapeutic targets and anticipate safety risks.</li>
            <li class="blog-paragraph"><strong>Loss-of-function/gain-of-function annotations </strong>and <strong>variant database information</strong> to aid in drug target nomination.</li>
          </ul>
          </br>
        </div>
        <div>
            <h2 class="blog-subtitle"></h2>
            <h5 class="blog-sub-subtitle">Start searching DISGENET <a href="https://disgenet.com/" target="_blank">here</a> (no account needed)</h5>
            <h5 class="blog-sub-subtitle">Book a Free Demo <a href="https://calendly.com/medbioinformatics/disgenet-demo?" target="_blank">here</a></h5>
             <p class="blog-paragraph">Contact us <strong>info@disgenet.com</strong></p>
        </div>
        <div>
          <h5 class="blog-subtitle">References</h5>
          <p>[1] Cook, D., Brown, D., Alexander, R., March, R., Morgan, P., Satterthwaite, G. et al. (2014) Lessons learned from the fate of AstraZeneca’s drug pipeline: a five-dimensional framework. Nature Reviews Drug Discovery, 13, 419–31. <a href="https://doi.org/10.1038/nrd4309" target="_blank">https://doi.org/10.1038/nrd4309</a></p>
          <p>[2] Minikel, E.V., Painter, J.L., Dong, C.C. and Nelson, M.R. (2024) Refining the impact of genetic evidence on clinical success. Nature, 629, 624–9. <a href="https://doi.org/10.1038/s41586-024-07316-0" target="_blank">https://doi.org/10.1038/s41586-024-07316-0</a></p>
          <p>[3] Nelson, M.R., Johnson, T., Warren, L., Hughes, A.R., Chissoe, S.L., Xu, C.-F. et al. (2016) The genetics of drug efficacy: opportunities and challenges. Nature Reviews Genetics, 17, 197–206. <a href="https://doi.org/10.1038/nrg.2016.12" target="_blank">https://doi.org/10.1038/nrg.2016.12</a></p>
          <p>[4] King, E.A., Davis, J.W. and Degner, J.F. (2019) Are drug targets with genetic support twice as likely to be approved? Revised estimates of the impact of genetic support for drug mechanisms on the probability of drug approval. Marchini J, editor. PLOS Genetics, 15, e1008489. <a href="https://doi.org/10.1371/journal.pgen.1008489" target="_blank">https://doi.org/10.1371/journal.pgen.1008489</a></p>
        </div>
      </div>
         
    `;
  }
}

posts.forEach(post => {
  post.content = generateContent(post);
});

export { posts };
