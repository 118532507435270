import React, { useState, createContext, useContext } from 'react';
import { Color } from '../ReactDataGrid/CommonGrid'; //todo: refactor and delete
import { DATASETS, getTitle, getName, getId } from '../Constants';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import { searchContext } from '../../Context/Context';
import updateParams from '../Utils/UpdateParams';
import HelpPopups from '../About/AboutPopups';
import dropdownLists from './DropdownLists';

import {
  getGeneNCBIid,
  getDiseaseName,
  getDiseaseLink,
  getDisease,
  getPmidLink,
  variantLink,
  getNctidLink,
} from '../ReactDataGrid/MappingFunctions';

import {
  renderAlleleDataVDA,
  renderAlleleDataVariant,
  renderVariantLink,
  renderSentenceHTML,
  renderDiseaseClassesAttributes,
  renderDecimalValues,
  renderListsFromObjects,
  renderDiseaseNameDDA,
  renderRoundedValues,
  renderJaccard,
  renderNCTid,
} from '../ReactDataGrid/CommonAtributes';

import { Link, useLocation } from 'react-router-dom';
import { use } from 'cytoscape';
import { update } from 'lodash';
import { data } from 'jquery';

const EXT_NCBI_GENE_LINK = 'https://www.ncbi.nlm.nih.gov/gene/';
const EXT_NCBI_DISEASE_LINK = 'https://www.ncbi.nlm.nih.gov/medgen/';
const EXT_UNIPROT_LINK = 'https://www.uniprot.org/uniprot/';
const EXT_ENSEMBL_LINK = 'https://www.ensembl.org/Homo_sapiens/Gene/Summary?g=';

function ButtonInfoGeneContent({ value }) {
  const { setGeneContent } = searchContext();
  return (
    <button onClick={() => setGeneContent({ isActive: true, geneInfo: value })}>
      <i className="fa fa-info-circle geneInfoBtn" />
    </button>
  );
}
function OpenPopup({ popupType }) {
  const [showPopup, setShowPopup] = useState(false);

  const handleClick = () => {
    setShowPopup(true);
  };

  return (
    <>
      <button onClick={handleClick}>
        <i className="fa fa-question-circle px-2 geneInfoBtn" />
      </button>

      <HelpPopups
        showMetrics={popupType === 'metrics' && showPopup}
        setShowMetrics={() => setShowPopup(false)}
        showAttributes={popupType === 'attributes' && showPopup}
        setShowAttributes={() => setShowPopup(false)}
        showGDA={popupType === 'showGDA' && showPopup}
        setShowGDA={() => setShowPopup(false)}
        showVDA={popupType === 'showVDA' && showPopup}
        setShowVDA={() => setShowPopup(false)}
        showDDA={popupType === 'showDDA' && showPopup}
        setShowDDA={() => setShowPopup(false)}
        showScore={popupType === 'showScore' && showPopup}
        setShowScore={() => setShowPopup(false)}
      />
    </>
  );
}
function ScoreHeader() {
  const { tab } = searchContext();
  return tab === 'GDA' ? (
    <>
      <span title="Gene-Disease Association Score">
        Score<sub>gda</sub>
      </span>
      <OpenPopup popupType="showScore" />
    </>
  ) : (
    <span title="Variant-Disease Association Score">
      Score<sub>vda</sub>
    </span>
  );
}
const Columns = [
  {
    name: 'exome',
    sortable: false,
    header: <span title="Allele Frequency in GNOMAD Exomes">AF EXOME</span>,
    uniqueID: 'AFExome',
    headerProps: { style: Color },
    render: instance => renderAlleleDataVariant(instance, 'exome'),
  },
  {
    name: 'alleleFreqExome',
    sortable: false,
    header: (
      <>
        <span title="Allele Frequency in GNOMAD Exomes">AF EXOME</span> <OpenPopup popupType="showDDA" />
      </>
    ),
    headerProps: { style: Color },
    // render: instance => renderAlleleData(instance, 'alleleFreqExome'),
    render: row => {
      const { view, tab } = searchContext();
      if (tab === 'VARIANTS') {
        return renderAlleleDataVariant(row, 'alleleFreqExome');
      } else if (tab === 'VDA') {
        return renderAlleleDataVDA(row, 'alleleFreqExome');
      } else {
        // console.error('Edge case not handled');
      }
    },
    minWidth: 115,
  },
  {
    name: 'genome',
    sortable: false,
    header: <span title="Allele Frequency in GNOMAD Genomes">AF GENOME</span>,
    uniqueID: 'AFGenome',
    headerProps: { style: Color },
    render: instance => renderAlleleDataVariant(instance, 'genome'),
  },
  {
    name: 'alleleFreqGenome',
    sortable: false,
    header: (
      <>
        <span title="Allele Frequency in GNOMAD Genomes">AF GENOME</span> <OpenPopup popupType="attributes" />
      </>
    ),
    headerProps: { style: Color },
    // render: instance => renderAlleleData(instance, 'alleleFreqGenome'),
    render: row => {
      const { view, tab } = searchContext();
      if (tab === 'VARIANTS') {
        return renderAlleleDataVariant(row, 'alleleFreqGenome');
      } else if (tab === 'VDA') {
        return renderAlleleDataVDA(row, 'alleleFreqGenome');
      } else {
        // console.error('Edge case not handled');
      }
    },
    minWidth: 115,
  },
  {
    name: 'alleles',
    sortable: false,
    header: (
      <>
        <span title="Reference/Alternative Allele">Alleles</span> <OpenPopup popupType="attributes" />
      </>
    ),
    headerProps: { style: Color },
    minWidth: 115,

    render: ({ data }) => {
      const { view, tab } = searchContext();

      let alleles = '';
      if (tab === 'VARIANTS') {
        const allelesArray = data.alleles?.map(value => `${value.ref}\\${value.alt}`);
        alleles = allelesArray.join('; ');
      } else if (tab === 'VDA') {
        const ref = data.alleles_ref;
        const alt = data.alleles_alt;
        alleles = `${ref}/${alt}`;
      } else {
        // console.error('Edge case not handled');
      }

      return <span>{alleles}</span>;
    },
  },
  {
    name: 'associationType',
    sortable: true,
    header: (
      <>
        <span title="Association Type">Association Type</span>
        {/* <OpenPopup popupType="sources" */}
      </>
    ),
    filterEditor: SelectFilter,
    filterEditorProps: props => {
      // can't use hook calls in here because it's not implemented as a react component
      const isGDA = location.search.includes('tab=GDA');
      const isVDA = location.search.includes('tab=VDA');
      const dropdown = isGDA ? dropdownLists.associationTypeGda : dropdownLists.associationTypeVda;
      return {
        dataSource: dropdown?.map(c => ({
          id: c,
          label: c.replace(/([A-Z])/g, ' $1').trim(),
        })),
        constrainTo: '.InovuaReactDataGrid__body',
      };
    },
    headerProps: {
      style: Color,
    },
    minWidth: 170,
    render: ({ value }) => {
      return value?.replace(/([A-Z])/g, ' $1').trim();
    },
  },
  {
    name: 'chemNumGDAs',
    sortable: true,
    header: <span title="Number of Gene-Disease Associations related to this chemical">N GDAs</span>,
    headerProps: { style: Color },
    minWidth: 240,
    render: ({ data }) => {
      const { view, tab } = searchContext();

      let newUrl = '';
      let newParams = '';
      let location = useLocation();
      // if (['CHEMICALS', 'GDA', 'VDA'].includes(tab)) {
      if (tab === 'CHEMICALS') {
        // Navigates from Chemicals browse view
        // to ? pivot on the selected ?
        newParams = updateParams({
          view: DATASETS.CHEMICALS,
          tab: 'GDA',
          searchItems: [{ id: data.chemUMLSCUI }],
        });
      } else if (tab === 'GDA') {
        // Navigates from N. Chemicals Mapping
        // to ? pivot on the selected ?
        newParams = updateParams({
          view: DATASETS.CHEMICALS,
          tab: 'GDA',
          searchItems: [{ id: data.chemUMLSCUI }],
        });
      } else if (tab === 'VDA') {
        // Navigates from N. Chemicals Mapping
        // to ? pivot on the selected ?
        newParams = updateParams({
          view: DATASETS.CHEMICALS,
          tab: 'GDA',
          searchItems: [{ id: data.chemUMLSCUI }],
        });
      } else {
        // console.error('Edge case not handled');
      }
      newUrl = `${location.pathname}?${newParams}`;

      return (
        <Link className="tab-links" to={newUrl}>
          {data.chemNumGDAs}{' '}
        </Link>
      );
    },
  },
  {
    name: 'chemNumPublications',
    sortable: true,
    header: <span title="Number of Publications mentioning the chemical">N PMIDs</span>,
    headerProps: { style: Color },
    minWidth: 205,
    render: row => {
      const chemNumPubli = row.data.chemNumPublications;
      return <span> {chemNumPubli} </span>;
    },
  },
  {
    name: 'chemNumPublicationsGDA',
    sortable: true,
    header: (
      <span title="Number of Publications mentioning this disease-gene association containing the chemical">
        N PMIDs this GDA
      </span>
    ),
    headerProps: { style: Color },
    minWidth: 195,
    render: ({ data }) => {
      const { view, tab, setMappingContent } = searchContext();

      let mappingItems = [];
      let columVisibilityModelKey = '';
      if (tab === 'GDA') {
        columVisibilityModelKey = 'GDA_ALL'; // renders both summary and evidence
        mappingItems = [
          {
            filterParam: 'disease',
            filterValue: data.diseaseUMLSCUI, // 'C0028754'
            name: data.diseaseName, // 'Obesity'
            id: data.diseaseUMLSCUI, // 'C0028754'
          },
          {
            filterParam: 'gene_ncbi_id',
            filterValue: data.geneNcbiID, // '5443'
            name: data.geneSymbol, // 'POMC'
            id: data.geneDescription, // 'proopiomelanocortin'
          },
          {
            filterParam: 'chem_cuis_init',
            filterValue: data.chemUMLSCUI, // C0025598
            name: data.chemShowName, // metformin
            id: data.chemUMLSCUI, // C0025598
          },
        ];
      } else {
        // console.error('Edge case not handled');
      }
      return (
        <>
          <button
            className="tab-links"
            onClick={() =>
              setMappingContent(prevContent => ({
                name: 'chemNumPublicationsGDA',
                isActive: true,
                tabSegment: 'gda',
                viewSegment: '',
                mappingItems: mappingItems,
                columVisibilityModelKey: columVisibilityModelKey,
              }))
            }
          >
            {data.chemNumPublicationsGDA}{' '}
          </button>
        </>
      );
    },
  },
  {
    name: 'chemNumPublicationsGDAs',
    sortable: true,
    header: (
      <span title="Number of Publications mentioning all disease-gene association containing the chemical">
        N PMIDs all GDAs
      </span>
    ),
    headerProps: { style: Color },
    minWidth: 230,
    render: ({ data }) => {
      const { view, tab } = searchContext();

      let newUrl = '';
      let newParams = '';
      let location = useLocation();
      if (['CHEMICALS', 'GDA', 'VDA'].includes(tab)) {
        // Navigates from Chemicals browse view
        // to ? pivot on the selected ?
        newParams = updateParams({
          view: DATASETS.CHEMICALS,
          tab: 'GDA',
          searchItems: [{ id: data.chemUMLSCUI }],
        });
      } else {
        // console.error('Edge case not handled');
      }
      newUrl = `${location.pathname}?${newParams}`;

      return (
        <Link className="tab-links" to={newUrl}>
          {data.chemNumPublicationsGDAs}{' '}
        </Link>
      );
    },
  },
  {
    name: 'chemNumPublicationsVDA',
    sortable: true,
    header: (
      <span title="Number of Publications mentioning this disease-variant association containing the chemical">
        N PMIDs this VDA
      </span>
    ),
    headerProps: { style: Color },
    minWidth: 195,
    render: ({ data }) => {
      const { view, tab, setMappingContent } = searchContext();

      let mappingItems = [];
      let columVisibilityModelKey = '';
      if (tab === 'VDA') {
        columVisibilityModelKey = 'VDA_ALL'; // renders both summary and evidence
        mappingItems = [
          {
            filterParam: 'disease',
            filterValue: data.diseaseUMLSCUI, // 'C0028754'
            name: data.diseaseName, // 'Obesity'
            id: data.diseaseUMLSCUI, // 'C0028754'
          },
          {
            filterParam: 'variant',
            filterValue: data.variantStrID, // 'rs4994'
            name: data.variantStrID, // 'rs4994'
            id: data.geneSymbol, // 'ADRB3'
          },
          {
            filterParam: 'chem_cuis_init',
            filterValue: data.chemUMLSCUI, // C0025598
            name: data.chemShowName, // metformin
            id: data.chemUMLSCUI, // C0025598
          },
        ];
      } else {
        // console.error('Edge case not handled');
      }
      return (
        <>
          <button
            className="tab-links"
            onClick={() =>
              setMappingContent(prevContent => ({
                name: 'chemNumPublicationsVDA',
                isActive: true,
                tabSegment: 'vda',
                viewSegment: '',
                mappingItems: mappingItems,
                columVisibilityModelKey: columVisibilityModelKey,
              }))
            }
          >
            {data.chemNumPublicationsVDA}{' '}
          </button>
        </>
      );
    },
  },
  {
    name: 'chemNumPublicationsVDAs',
    sortable: true,
    header: (
      <span title="Number of Publications mentioning all disease-variant association containing the chemical">
        N PMIDs all VDAs
      </span>
    ),
    headerProps: { style: Color },
    minWidth: 230,
    render: ({ data }) => {
      const { view, tab } = searchContext();

      let newUrl = '';
      let newParams = '';
      let location = useLocation();
      if (['CHEMICALS', 'GDA', 'VDA'].includes(tab)) {
        // Navigates from Chemicals browse view
        // to ? pivot on the selected ?
        newParams = updateParams({
          view: DATASETS.CHEMICALS,
          tab: 'GDA',
          searchItems: [{ id: data.chemUMLSCUI }],
        });
      } else {
        // console.error('Edge case not handled');
      }
      newUrl = `${location.pathname}?${newParams}`;

      return (
        <Link className="tab-links" to={newUrl}>
          {data.chemNumPublicationsVDAs}{' '}
        </Link>
      );
    },
  },
  {
    name: 'chemNumVDAs',
    sortable: true,
    header: <span title="Number of Variant-Disease Associations related to this chemical">N VDAs</span>,
    headerProps: { style: Color },
    minWidth: 230,
    render: ({ data }) => {
      const { view, tab } = searchContext();

      let newUrl = '';
      let newParams = '';
      let location = useLocation();
      if (['CHEMICALS', 'GDA', 'VDA'].includes(tab)) {
        // Navigates from Chemicals browse view
        // to ? pivot on the selected ?
        newParams = updateParams({
          view: DATASETS.CHEMICALS,
          tab: 'VDA',
          searchItems: [{ id: data.chemUMLSCUI }],
        });
      } else {
        // console.error('Edge case not handled');
      }
      newUrl = `${location.pathname}?${newParams}`;

      return (
        <Link className="tab-links" to={newUrl}>
          {data.chemNumVDAs}{' '}
        </Link>
      );
    },
  },
  {
    name: 'chemShowName',
    sortable: true,
    header: <span title="Chemical Official Name">Chemical</span>,
    headerProps: { style: Color },
    minWidth: 240,
  },
  {
    name: 'chemsInSentence',
    sortable: true,
    header: <span title="Chemicals in sentence">Chemicals in sentence</span>,
    headerProps: { style: Color },
    minWidth: 240,
    render: instance => renderListsFromObjects(instance, 'chemsInSentence', 'chemShowName'),
  },
  {
    name: 'chemUMLSCUI',
    sortable: true,
    header: <span title="Chemical UMLSCUI">Chemical CUI</span>,
    headerProps: { style: Color },
    minWidth: 205,
  },
  {
    name: 'chemUMLSCUIs',
    sortable: true,
    header: <span title="Chemicals UMLSCUIs">Chemicals CUI</span>,
    headerProps: { style: Color },
    render: instance => renderListsFromObjects(instance, 'chemsInSentence', 'chemUMLSCUI'),
    minWidth: 130,
  },
  {
    name: 'chromosome',
    sortable: true,
    header: (
      <>
        <span title="Chromosome">Chr</span> <OpenPopup popupType="attributes" />
      </>
    ),
    headerProps: { style: Color },
    minWidth: 85,
  },
  {
    name: 'coord',
    sortable: true,
    header: (
      <>
        <span title="Position in the Chromosome">Position</span> <OpenPopup popupType="attributes" />
      </>
    ),
    headerProps: { style: Color },
    minWidth: 115,
  },
  {
    name: 'DBSNPclass',
    sortable: true,
    header: (
      <>
        <span title="Class of variant">Variant type</span> <OpenPopup popupType="attributes" />
      </>
    ),
    uniqueID: 'DBSNPClass',
    headerProps: { style: Color },
    filterEditor: SelectFilter,
    filterEditorProps: {
      dataSource: dropdownLists.DBSNPclass?.map(c => {
        return { id: c, label: c };
      }),
      constrainTo: '.InovuaReactDataGrid__body',
    },
    render: ({ data }) => {
      const { tab } = searchContext();
      if (tab === 'VARIANTS') {
        return data.dbnspClass;
      } else {
        return data.DBSNPclass;
      }
    },
  },
  {
    name: 'ddaRelation',
    sortable: true,
    header: <span title="DDA Relation">DDA Relation</span>,
    headerProps: { style: Color },
    minWidth: 265,
    filterEditor: SelectFilter,
    filterEditorProps: {
      dataSource: dropdownLists.ddaRelation?.map(item => ({
        id: item.split(' ').join('_'),
        label: item,
      })),
    },
    // render: ({ value }) => {
    //   value ? value?.replace(/_/g, ' ') : '';
    // },
  },
  {
    name: 'description', // todo: remap this to geneDescription
    sortable: true,
    header: <span title="Gene Full Name">Gene Full Name</span>,
    headerProps: { style: Color },
    minWidth: 295,
  },
  {
    name: 'disease1_Name',
    sortable: true,
    header: <span title="Index disease">Disease 1</span>,
    headerProps: { style: Color },
    render: instance => renderDiseaseNameDDA(instance, 1),
  },
  {
    name: 'disease1_Type',
    sortable: true,
    header: (
      <span title="Type of the index Disease">
        Disease Type <sub>d1</sub>
      </span>
    ),
    filterEditor: SelectFilter,
    filterEditorProps: {
      dataSource: dropdownLists.disease1_Type?.map(c => {
        return { id: c, label: c };
      }),
      constrainTo: '.InovuaReactDataGrid__body',
    },
    headerProps: { style: Color },
    minWidth: 130,
  },
  {
    name: 'disease1ClassName_DO',
    sortable: true,
    header: (
      <span title="Ontology Top Class of the index Disease">
        DO Class <sub>d1</sub>
      </span>
    ),
    headerProps: { style: Color },
    filterEditor: SelectFilter,
    filterEditorProps: {
      dataSource: dropdownLists.disease1ClassName_DO?.map(c => ({
        id: c,
        label: c.replace(/\b\w/g, match => match.toUpperCase()),
      })),
      constrainTo: '.InovuaReactDataGrid__body',
    },
    render: ({ value }) => renderDiseaseClassesAttributes(value),
  },

  {
    name: 'disease1ClassName_HPO',
    sortable: true,
    header: (
      <>
        <span title="Human Phenotye Ontology Top Class of the index Disease">
          HPO Class <sub>d1</sub>
        </span>{' '}
      </>
    ),
    render: ({ value }) => renderDiseaseClassesAttributes(value),
    headerProps: { style: Color },
    filterEditor: SelectFilter,
    filterEditorProps: {
      dataSource: dropdownLists.disease1ClassName_HPO?.map(c => ({
        id: c,
        label: c.replace(/\b\w/g, match => match.toUpperCase()),
      })),
      constrainTo: '.InovuaReactDataGrid__body',
    },
  },
  {
    name: 'disease1ClassName_MSH',
    sortable: true,
    header: (
      <span title="MeSH Class of the index Disease">
        Disease Class <sub>d1</sub>
      </span>
    ),
    render: ({ value }) => renderDiseaseClassesAttributes(value),
    headerProps: { style: Color },
    filterEditor: SelectFilter,
    filterEditorProps: {
      dataSource: dropdownLists.disease1ClassName_MSH?.map(c => {
        return { id: c, label: c };
      }),
      constrainTo: '.InovuaReactDataGrid__body',
    },
    minWidth: 385,
  },
  {
    name: 'disease1ClassName_UMLS',
    sortable: true,
    header: (
      <span title="UMLS Semantic Type of the index Disease">
        Semantic Type <sub>d1</sub>
      </span>
    ),
    render: ({ value }) => renderDiseaseClassesAttributes(value),
    headerProps: { style: Color },
    filterEditor: SelectFilter,
    filterEditorProps: {
      dataSource: dropdownLists.disease1ClassName_UMLS?.map(c => {
        return { id: c, label: c };
      }),
      constrainTo: '.InovuaReactDataGrid__body',
    },
  },
  {
    name: 'disease2_Name',
    sortable: true,
    header: <span title="Disease associated with the index disease">Disease 2</span>,
    headerProps: { style: Color },
    render: instance => renderDiseaseNameDDA(instance, 2),
    minWidth: 295,
  },
  {
    name: 'disease2_Type',
    sortable: true,
    header: (
      <span title="Type of the associated Disease">
        Disease Type <sub>d2</sub>
      </span>
    ),
    filterEditor: SelectFilter,
    filterEditorProps: {
      dataSource: dropdownLists.disease2_Type?.map(c => {
        return { id: c, label: c };
      }),
      constrainTo: '.InovuaReactDataGrid__body',
    },
    headerProps: { style: Color },
    minWidth: 130,
  },
  {
    name: 'disease2ClassName_DO',
    sortable: true,
    header: (
      <span title="Ontology Top Class of the associated Disease">
        DO Class <sub>d2</sub>
      </span>
    ),
    headerProps: { style: Color },
    filterEditor: SelectFilter,
    filterEditorProps: {
      dataSource: dropdownLists.disease2ClassName_DO?.map(c => ({
        id: c,
        label: c.replace(/\b\w/g, match => match.toUpperCase()),
      })),
      constrainTo: '.InovuaReactDataGrid__body',
    },
    render: ({ value }) => renderDiseaseClassesAttributes(value),
  },
  {
    name: 'disease2ClassName_HPO',
    sortable: true,
    header: (
      <>
        <span title="Human Phenotye Ontology Top Class of the associated Disease">
          HPO Class <sub>d2</sub>
        </span>{' '}
        <OpenPopup popupType="attributes" />
      </>
    ),
    render: ({ value }) => renderDiseaseClassesAttributes(value),
    headerProps: { style: Color },
    filterEditor: SelectFilter,
    filterEditorProps: {
      dataSource: dropdownLists.disease2ClassName_HPO?.map(c => ({
        id: c,
        label: c.replace(/\b\w/g, match => match.toUpperCase()),
      })),
      constrainTo: '.InovuaReactDataGrid__body',
    },
  },
  {
    name: 'disease2ClassName_MSH',
    sortable: true,
    header: (
      <>
        <span title="MeSH Class of the associated Disease">
          Disease Class <sub>d2</sub>
        </span>{' '}
        <OpenPopup popupType="attributes" />
      </>
    ),
    render: ({ value }) => renderDiseaseClassesAttributes(value),
    headerProps: { style: Color },
    filterEditor: SelectFilter,
    filterEditorProps: {
      dataSource: dropdownLists.disease2ClassName_MSH?.map(c => {
        return { id: c, label: c };
      }),
      constrainTo: '.InovuaReactDataGrid__body',
    },
    minWidth: 385,
  },
  {
    name: 'disease2ClassName_UMLS',
    sortable: true,
    header: (
      <>
        <span title="UMLS Semantic Type of the associated Disease">
          Semantic Type <sub>d2</sub>
        </span>{' '}
        <OpenPopup popupType="attributes" />
      </>
    ),
    render: ({ value }) => renderDiseaseClassesAttributes(value),
    headerProps: { style: Color },
    filterEditor: SelectFilter,
    filterEditorProps: {
      dataSource: dropdownLists.disease2ClassName_UMLS?.map(c => {
        return { id: c, label: c };
      }),
      constrainTo: '.InovuaReactDataGrid__body',
    },
  },
  {
    name: 'diseaseClassName_DO',
    sortable: true,
    header: (
      <>
        <span title="Disease Ontology Top Class">DO Class</span> <OpenPopup popupType="attributes" />
      </>
    ),
    render: ({ value }) => renderDiseaseClassesAttributes(value),
    headerProps: { style: Color },
    filterEditor: SelectFilter,
    filterEditorProps: {
      dataSource: dropdownLists.diseaseClassName_DO?.map(c => ({
        id: c,
        label: c.replace(/\b\w/g, match => match.toUpperCase()),
      })),
      constrainTo: '.InovuaReactDataGrid__body',
    },
    minWidth: 295,
  },
  {
    name: 'diseaseClassName_HPO',
    sortable: true,
    header: (
      <>
        <span title="Human Phenotye Ontology Top Class">HPO Class</span> <OpenPopup popupType="attributes" />
      </>
    ),
    render: ({ value }) => renderDiseaseClassesAttributes(value),
    headerProps: { style: Color },
    filterEditor: SelectFilter,
    filterEditorProps: {
      dataSource: dropdownLists.diseaseClassName_HPO?.map(c => ({
        id: c,
        label: c.replace(/\b\w/g, match => match.toUpperCase()),
      })),
      constrainTo: '.InovuaReactDataGrid__body',
    },
    minWidth: 295,
  },
  {
    name: 'diseaseClassName_MSH',
    sortable: true,
    header: (
      <>
        <span title="MeSH Disease Class">Disease Class</span>
        <OpenPopup popupType="attributes" />
      </>
    ),
    render: ({ value }) => renderDiseaseClassesAttributes(value),
    headerProps: { style: Color },
    filterEditor: SelectFilter,
    filterEditorProps: {
      dataSource: dropdownLists.diseaseClassName_MSH?.map(c => ({ id: c, label: c })),
      constrainTo: '.InovuaReactDataGrid__body',
    },
    minWidth: 205,
  },
  {
    name: 'diseaseClassName_UMLS',
    sortable: true,
    header: (
      <>
        <span title="UMLS Semantic Type">Semantic Type</span>
        <OpenPopup popupType="attributes" />
      </>
    ),
    render: ({ value }) => renderDiseaseClassesAttributes(value),
    headerProps: { style: Color },
    filterEditor: SelectFilter,
    filterEditorProps: {
      dataSource: dropdownLists.diseaseClassName_UMLS?.map(c => ({ id: c, label: c })),
      constrainTo: '.InovuaReactDataGrid__body',
    },
    minWidth: 205,
  },
  {
    name: 'diseaseName',
    sortable: true,
    header: <span title="Disease Name">Disease</span>,
    headerProps: { style: Color },
    minWidth: 295,
    render: row => {
      const data = row.data;
      return <React.Fragment>{getDiseaseName({ data })}</React.Fragment>;
    },
  },
  {
    name: 'diseaseType',
    sortable: true,
    header: (
      <>
        <span title="Disease Type">Disease Type</span> <OpenPopup popupType="attributes" />
      </>
    ),
    filterEditor: SelectFilter,
    filterEditorProps: {
      dataSource: dropdownLists.diseaseType?.map(c => ({ id: c, label: c })),
      constrainTo: '.InovuaReactDataGrid__body',
    },
    headerProps: { style: Color },
    minWidth: 130,
  },
  {
    name: 'diseaseUMLSCUI',
    sortable: true,
    header: <span title="Disease UMLS CUI code">Disease CUI</span>,
    headerProps: { style: Color },
    minWidth: 130,
    render: row => {
      const disease = Array.isArray(row.data.diseaseUMLSCUI)
        ? row.data.diseaseUMLSCUI
        : [row.data.diseaseUMLSCUI];
      const formattedLinks = disease?.map((diseaseUMLSCUI, index) => (
        <React.Fragment key={index}>{getDiseaseLink({ diseaseUMLSCUI })}</React.Fragment>
      ));
      return formattedLinks;
    },
  },
  {
    name: 'DPI',
    sortable: true,
    header: (
      <>
        <span title="Disease Pleiotropy Index for the gene">
          DPI<sub>g</sub>
        </span>
        <OpenPopup popupType="metrics" />
      </>
    ),
    headerProps: { style: Color },
    render: instance => renderRoundedValues(instance, 'DPI'),
    minWidth: 85,
  },
  {
    name: 'DSI',
    sortable: true,
    header: (
      <>
        <span title="Disease Specificity Index for the gene">
          DSI<sub>g</sub>
        </span>
        <OpenPopup popupType="metrics" />
      </>
    ),
    headerProps: { style: Color },
    render: instance => renderRoundedValues(instance, 'DSI'),
    minWidth: 85,
  },
  {
    name: 'polyphen_score',
    sortable: true,
    header: (
      <>
        <span title="PolyPhen">PolyPhen</span>
      </>
    ),
    headerProps: { style: Color },
    render: instance => renderRoundedValues(instance, 'polyphen_score'),
    minWidth: 85,
  },
  {
    name: 'sift_score',
    sortable: true,
    header: (
      <>
        <span title="SIFT">SIFT</span>
      </>
    ),
    headerProps: { style: Color },
    render: instance => renderRoundedValues(instance, 'sift_score'),
    minWidth: 85,
  },
  {
    name: 'EI',
    sortable: true,
    header: (
      <>
        <span title="Evidence Index for the GDA">
          EI<sub>gda</sub>
        </span>
        <OpenPopup popupType="metrics" />
      </>
    ),
    headerProps: { style: Color },
    filterEditorProps: {
      minValue: 0,
      maxValue: 1,
      step: 0.01,
    },
    render: instance => renderRoundedValues(instance, 'EI'),
    minWidth: 85,
  },
  {
    name: 'EL',
    sortable: true,
    header: (
      <>
        <span title="Evidence Level for the GDA">
          EL<sub>gda</sub>
        </span>
        <OpenPopup popupType="attributes" />
      </>
    ),
    filterEditor: SelectFilter,
    filterEditorProps: {
      dataSource: dropdownLists.EL?.map(c => {
        return { id: c, label: c };
      }),
      constrainTo: '.InovuaReactDataGrid__body',
    },
    headerProps: { style: Color },
    minWidth: 115,
  },
  {
    name: 'firstRef',
    sortable: true,
    minWidth: 115,
    header: (
      <>
        <span title="First time the disease was reported">First Ref.</span>
        <OpenPopup popupType="attributes" />
      </>
    ),
    headerProps: { style: Color },
  },
  {
    name: 'geneDescription',
    sortable: true,
    header: <span title="Gene Full Name">Gene Full Name</span>,
    headerProps: { style: Color },
    minWidth: 265,
  },
  {
    name: 'geneDPI',
    sortable: true,
    header: (
      <>
        <span title="Disease Pleiotropy Index for the gene">
          DPI<sub>g</sub>
        </span>
        <OpenPopup popupType="metrics" />
      </>
    ),
    headerProps: { style: Color },
    minWidth: 105,
  },
  {
    name: 'geneDSI',
    sortable: true,
    header: (
      <>
        <span title="Disease Specificity Index for the gene">
          DSI<sub>g</sub>
        </span>
        <OpenPopup popupType="metrics" />
      </>
    ),
    headerProps: { style: Color },
    minWidth: 80,
  },
  {
    name: 'geneEnsemblIDs',
    sortable: true,
    header: <span title="Gene ENSEMBL id">Gene ENSEMBL Id</span>,
    headerProps: { style: Color },
    minWidth: 130,
    render: ({ value }) => {
      const delimiter = '|';
      const formattedLinks = value?.map((val, index) => (
        <span key={val}>
          <a href={`${EXT_ENSEMBL_LINK}${val}`} target="_blank" rel="noopener noreferrer">
            {value[index]}
            {val && <i className="fa fa-external-link px-1" aria-hidden="true"></i>}
          </a>
          <a className="px-1">{index !== value?.length - 1 && delimiter}</a>
        </span>
      ));
      return <span>{formattedLinks}</span>;
    },
  },
  {
    name: 'geneNcbiID',
    sortable: true,
    header: <span title="Gene NCBI id">Gene NCBI Id</span>,
    headerProps: { style: Color },
    minWidth: 130,
    render: row => {
      const geneNcbis = row.data.geneNcbiID;
      const linkout = geneNcbis ? getGeneNCBIid(geneNcbis) : '';
      return <React.Fragment>{linkout}</React.Fragment>;
    },
  },
  {
    name: 'geneNcbiIDs',
    sortable: true,
    header: <span title="Genes NCBI id">Gene NCBI Id</span>,
    headerProps: { style: Color },
    minWidth: 130,
    render: row => {
      const geneNcbis = row.data.geneNcbiIDs;
      const linkout = geneNcbis ? getGeneNCBIid(geneNcbis) : '';
      return <React.Fragment>{linkout}</React.Fragment>;
    },
  },
  {
    name: 'geneNcbiType',
    sortable: true,
    header: <span title="Gene Type">Gene Type</span>,
    headerProps: { style: Color },
    filterEditor: SelectFilter,
    filterEditorProps: {
      dataSource: dropdownLists.geneNcbiType?.map(c => ({ id: c, label: c })),
      constrainTo: '.InovuaReactDataGrid__body',
    },
    minWidth: 125,
  },
  {
    name: 'genepLI',
    sortable: true,
    header: (
      <>
        <span title="Probability of being loss of function intolerant">pLI</span>{' '}
        <OpenPopup popupType="attributes" />
      </>
    ),
    headerProps: { style: Color },
  },
  {
    name: 'geneProteinClassNames',
    sortable: true,
    header: (
      <>
        <span title="Protein classification according to the DrugTarget Ontology">Protein Class</span>{' '}
        <OpenPopup popupType="attributes" />
      </>
    ),
    filterEditor: SelectFilter,
    filterEditorProps: {
      dataSource: dropdownLists.geneProteinClassNames?.map(c => {
        return { id: c, label: c };
      }),
      constrainTo: '.InovuaReactDataGrid__body',
    },
    headerProps: { style: Color },
  },
  {
    name: 'geneProteinStrIDs',
    sortable: true,
    header: (
      <>
        <span title="UniProt Accession">UniProt</span> <OpenPopup popupType="attributes" />
      </>
    ),
    headerProps: { style: Color },
    minWidth: 205,
    render: ({ data }) => {
      const { view, tab } = searchContext();

      let ids = [];
      let values = [];
      const delimiter = '|';

      if (tab === 'GDA' || tab === 'DDA') {
        ids = data.geneProteinStrIDs;
        values = data.geneProteinStrIDs;
      } else {
        // console.error('Edge case not handled');
      }
      const formattedLinks = ids?.map((id, index) => (
        <span key={id}>
          <a href={`${EXT_UNIPROT_LINK}${id}`} target="_blank" rel="noopener noreferrer">
            {values[index]}
            {id && <i className="fa fa-external-link px-1" aria-hidden="true"></i>}
          </a>
          <a className="px-1">{index !== ids?.length - 1 && delimiter}</a>
        </span>
      ));
      return <span className="geneModalLink">{formattedLinks}</span>;
    },
  },
  {
    name: 'symbol',
    sortable: true,
    header: <span title="Gene Symbol">Gene</span>,
    uniqueID: 'Gene',
    headerProps: { style: Color },
    columnSize: null,
    render: ({ data }) => {
      const { view, tab } = searchContext();

      let ids = [];
      let values = [];
      let geneInfo = undefined;
      let isGene = false;
      const delimiter = '|';

      if (tab === 'GENES') {
        // for GENES browse view
        isGene = true;
        ids = [data.ncbiID];
        values = [data.symbol];
        geneInfo = { symbol: data.symbol, id: data.ncbiID };
      } else {
        // console.error('Edge case not handled');
      }
      const formattedLinks = ids?.map((id, index) => (
        <span key={id}>
          <a href={`${EXT_NCBI_GENE_LINK}${id}`} target="_blank" rel="noopener noreferrer">
            {values[index]}
            {id && <i className="fa fa-external-link px-1" aria-hidden="true"></i>}
          </a>
          <a className="px-1">{index !== ids?.length - 1 && delimiter}</a>
        </span>
      ));

      return (
        <span className="geneModalLink">
          {formattedLinks}
          {isGene && <ButtonInfoGeneContent value={geneInfo} />}
        </span>
      );
    },
  },
  {
    name: 'geneSymbol',
    sortable: true,
    header: <span title="Gene Symbol">Gene</span>,
    headerProps: { style: Color },
    render: ({ data }) => {
      const { view, tab } = searchContext();

      let ids = [];
      let values = [];
      let geneInfo = undefined;
      let isGene = false;
      const delimiter = '|';

      if (tab === 'GDA') {
        // for GDA based views
        isGene = true;
        ids = [data.geneNcbiID];
        values = [data.geneSymbol];
        geneInfo = { symbol: data.geneSymbol, id: data.geneNcbiID };
      } else if (tab === 'DDA') {
        // for DDA based views with different ID logic
        isGene = true;
        if (data.geneNcbiID) {
          ids = [data.geneNcbiID];
        } else if (data.geneNcbiIDs) {
          ids = data.geneNcbiIDs;
        }
        values = [data.geneSymbol];
        geneInfo = { symbol: data.geneSymbol, id: data.geneNcbiID || data.geneNcbiIDs };
      } else if (tab == 'VDA' && !data.geneNcbiIDs?.length) {
        // For VDA based views with no linked genes
        return '';
      } else if (tab == 'VDA' && data.geneNcbiIDs?.length === data.geneSymbol?.length) {
        // For VDA based views with linked Gene(s)
        // map .geneSymbol with the external link .geneNcbiIDs
        ids = data.geneNcbiIDs;
        values = data.geneSymbol;
      } else if (tab === 'VARIANTS') {
        ids = data.variantToGenes?.map(element => element.geneNcbiID);
        values = data.variantToGenes?.map(element => element.geneSymbol);
      } else {
        // console.error('Edge case not handled');
      }

      const formattedLinks = ids?.map((id, index) => (
        <span key={id}>
          <a href={`${EXT_NCBI_GENE_LINK}${id}`} target="_blank" rel="noopener noreferrer">
            {values[index]}
            {id && <i className="fa fa-external-link px-1" aria-hidden="true"></i>}
          </a>
          <a className="px-1">{index !== ids?.length - 1 && delimiter}</a>
        </span>
      ));
      return (
        <span className="geneModalLink">
          {formattedLinks}
          {tab === 'GDA' && <ButtonInfoGeneContent value={geneInfo} />}
        </span>
      );
    },
  },
  {
    name: 'gof_lof',
    sortable: true,
    header: <span title="GOF / LOF">GOF / LOF</span>,
    filterEditor: SelectFilter,
    filterEditorProps: {
      dataSource: dropdownLists.gof_lof?.map(c => {
        return { id: c, label: c };
      }),
      constrainTo: '.InovuaReactDataGrid__body',
    },
    headerProps: { style: Color },
    minWidth: 115,
  },
  {
    name: 'jaccard_genes',
    sortable: true,
    header: (
      <span title="Jaccard index based on shared genes">
        JI<sub>g</sub>
      </span>
    ),
    headerProps: { style: Color },
    render: instance => renderJaccard(instance, 'GENE'),
  },
  {
    name: 'jaccard_variants',
    sortable: true,
    header: (
      <span title="Jaccard index based on shared variants">
        JI<sub>v</sub>
      </span>
    ),
    headerProps: { style: Color },
    render: instance => renderJaccard(instance, 'VARIANT'),
  },
  {
    name: 'journal_abbrv',
    sortable: true,
    header: <span title="Name of the Journal">Journal</span>,
    headerProps: { style: Color },
    render: ({ value }) => <span title={value}>{value}</span>,
    minWidth: 295,
  },
  {
    name: 'lastRef',
    sortable: true,
    minWidth: 115,
    header: (
      <>
        <span title="Last time the disease was reported">Last Ref.</span>
        <OpenPopup popupType="attributes" />
      </>
    ),
    headerProps: { style: Color },
  },
  {
    name: 'mostSevereConsequences',
    sortable: true,
    header: (
      <>
        <span title="Most Severe VEP Consequence Type">Consequence</span>
        <OpenPopup popupType="attributes" />
      </>
    ),
    filterEditor: SelectFilter,
    filterEditorProps: {
      dataSource: dropdownLists.mostSevereConsequences?.map(c => {
        return { id: c, label: c };
      }),
      constrainTo: '.InovuaReactDataGrid__body',
    },
    headerProps: { style: Color },
  },
  {
    name: 'name',
    sortable: true,
    header: <span title="Disease Name">Disease</span>,
    headerProps: { style: Color },
    minWidth: 295,
    render: row => {
      const data = row.data;
      return <React.Fragment>{getDisease({ data })}</React.Fragment>;
    },
  },
  {
    name: 'ncbi_type',
    sortable: true,
    header: <span title="Gene Type">Gene Type</span>,
    headerProps: { style: Color },
    filterEditor: SelectFilter,
    filterEditorProps: {
      dataSource: dropdownLists.ncbi_type?.map(c => {
        return { id: c, label: c };
      }),
      constrainTo: '.InovuaReactDataGrid__body',
    },
  },
  {
    name: 'ncbiID',
    sortable: true,
    header: <span title="Gene NCBI identifier">Gene NCBI</span>,
    headerProps: { style: Color },
    minWidth: 130,
    render: row => {
      const geneNcbis = row.data.ncbiID;
      const linkout = geneNcbis ? getGeneNCBIid(geneNcbis) : '';
      return <React.Fragment>{linkout}</React.Fragment>;
    },
  },
  {
    name: 'nct_id',
    sortable: false,
    header: <span title="NCT ID">NCT ID</span>,
    render: instance => renderNCTid(instance),
    headerProps: { style: Color },
    minWidth: 295,
  },
  {
    name: 'ngenes_diseaseID_1',
    sortable: true,
    header: (
      <span title="Number of genes of the index Disease">
        N genes<sub>d1</sub>
      </span>
    ),
    headerProps: { style: Color },
    minWidth: 95,
    render: ({ data }) => {
      const { view, tab, setMappingContent } = searchContext();

      let mappingItems = [];
      let columVisibilityModelKey = '';
      let tabSegment = '';
      if (tab === 'DDA') {
        columVisibilityModelKey = 'ngenes_diseaseID';
        tabSegment = 'gda';
        mappingItems = [
          {
            filterParam: 'disease',
            filterValue: data.disease1_UMLSCUI, // 'C0028754'
            name: data.disease1_Name, // 'Obesity'
            id: data.disease1_UMLSCUI, // 'C0028754'
          },
        ];
      } else {
        // console.error('Edge case not handled');
      }

      return (
        <>
          <button
            className="tab-links"
            onClick={() =>
              setMappingContent(prevContent => ({
                name: 'ngenes_diseaseID_1',
                isActive: true,
                tabSegment: tabSegment,
                viewSegment: '',
                mappingItems: mappingItems,
                columVisibilityModelKey: columVisibilityModelKey,
              }))
            }
          >
            {data.ngenes_diseaseID_1}{' '}
          </button>
        </>
      );
    },
  },
  {
    name: 'ngenes_diseaseID_2',
    sortable: true,
    header: (
      <span title="Number of Genes of the associated Disease">
        N Genes <sub>d2</sub>
      </span>
    ),
    headerProps: { style: Color },
    minWidth: 125,
    render: ({ data }) => {
      const { view, tab, setMappingContent } = searchContext();

      let mappingItems = [];
      let columVisibilityModelKey = '';
      let tabSegment = '';
      if (tab === 'DDA') {
        columVisibilityModelKey = 'ngenes_diseaseID';
        tabSegment = 'gda';
        mappingItems = [
          {
            filterParam: 'disease',
            filterValue: data.disease2_UMLSCUI, // 'C0028754'
            name: data.disease2_Name, // 'Obesity'
            id: data.disease2_UMLSCUI, // 'C0028754'
          },
        ];
      } else {
        // console.error('Edge case not handled');
      }

      return (
        <>
          <button
            className="tab-links"
            onClick={() =>
              setMappingContent(prevContent => ({
                name: 'ngenes_diseaseID_2',
                isActive: true,
                tabSegment: tabSegment,
                viewSegment: '',
                mappingItems: mappingItems,
                columVisibilityModelKey: columVisibilityModelKey,
              }))
            }
          >
            {data.ngenes_diseaseID_2}{' '}
          </button>
        </>
      );
    },
  },
  {
    name: 'numberChemsIncludedInEvidence',
    sortable: true,
    header: <span title="Number of Chemicals Included in the Evidences">N Chemicals</span>,
    headerProps: { style: Color },
    minWidth: 130,
    render: ({ data }) => {
      const { view, tab, setMappingContent } = searchContext();

      let mappingItems = [];
      let columVisibilityModelKey = '';
      let tabSegment = '';
      if (tab === 'GDA') {
        columVisibilityModelKey = 'numberChemsIncludedInEvidenceGDA';
        tabSegment = 'chem/gda';
        mappingItems = [
          {
            filterParam: 'disease',
            filterValue: data.diseaseUMLSCUI, // 'C0028754'
            name: data.diseaseName, // 'Obesity'
            id: data.diseaseUMLSCUI, // 'C0028754'
          },
          {
            filterParam: 'gene_ncbi_id',
            filterValue: data.geneNcbiID, // '5443'
            name: data.geneSymbol, // 'POMC'
            id: data.geneDescription, // 'proopiomelanocortin'
          },
        ];
      } else if (tab === 'VDA') {
        columVisibilityModelKey = 'numberChemsIncludedInEvidenceVDA';
        tabSegment = 'chem/vda';
        mappingItems = [
          {
            filterParam: 'disease',
            filterValue: data.diseaseUMLSCUI, // 'C0028754'
            name: data.diseaseName, // 'Obesity'
            id: data.diseaseUMLSCUI, // 'C0028754'
          },
          {
            filterParam: 'variant',
            filterValue: data.variantStrID, // 'rs4994'
            name: data.variantStrID, // 'rs4994'
            id: data.geneSymbol, // 'ADRB3'
          },
        ];
      } else {
        // console.error('Edge case not handled');
      }

      return (
        <>
          <button
            className="tab-links"
            onClick={() =>
              setMappingContent(prevContent => ({
                name: 'numberChemsIncludedInEvidence',
                isActive: true,
                tabSegment: tabSegment,
                viewSegment: '/all',
                mappingItems: mappingItems,
                columVisibilityModelKey: columVisibilityModelKey,
              }))
            }
          >
            {data.numberChemsIncludedInEvidence}{' '}
          </button>
        </>
      );
    },
  },
  {
    name: 'numberPmidsWithChemsFiltered',
    sortable: true,
    header: <span title="Number of Publications Associated to the Drug, Gene and Disease">N PMIDs</span>,
    headerProps: { style: Color },
    minWidth: 130,
    render: ({ data }) => {
      const { view, tab, setMappingContent, searchItems } = searchContext();

      let mappingItems = [];
      let columVisibilityModelKey = '';
      let tabSegment = '';
      const chemsOfInterest = searchItems.map(item => ({
        filterParam: 'chem_cuis_init',
        filterValue: item?.chemUMLSCUI,
        name: item?.chemShowName,
        id: item?.chemUMLSCUI,
      }));
      if (tab === 'GDA') {
        columVisibilityModelKey = 'numberPmidsWithChemsFilteredGDA';
        tabSegment = 'gda';
        mappingItems = [
          {
            filterParam: 'disease',
            filterValue: data.diseaseUMLSCUI, // 'C0028754'
            name: data.diseaseName, // 'Obesity'
            id: data.diseaseUMLSCUI, // 'C0028754'
          },
          {
            filterParam: 'gene_ncbi_id',
            filterValue: data.geneNcbiID, // '5443'
            name: data.geneSymbol, // 'POMC'
            id: data.geneDescription, // 'proopiomelanocortin'
          },
          ...chemsOfInterest,
        ];
      } else if (tab === 'VDA') {
        columVisibilityModelKey = 'numberPmidsWithChemsFilteredVDA';
        tabSegment = 'vda';
        mappingItems = [
          {
            filterParam: 'disease',
            filterValue: data.diseaseUMLSCUI, // 'C0028754'
            name: data.diseaseName, // 'Obesity'
            id: data.diseaseUMLSCUI, // 'C0028754'
          },
          {
            filterParam: 'variant',
            filterValue: data.variantStrID, // 'rs4994'
            name: data.variantStrID, // 'rs4994'
            id: data.geneSymbol, // 'ADRB3'
          },
          ...chemsOfInterest,
        ];
      } else {
        // console.error('Edge case not handled');
      }

      return (
        <>
          <button
            className="tab-links"
            onClick={() =>
              setMappingContent(prevContent => ({
                name: 'numberPmidsWithChemsFiltered',
                isActive: true,
                tabSegment: tabSegment,
                viewSegment: '',
                mappingItems: mappingItems,
                columVisibilityModelKey: columVisibilityModelKey,
              }))
            }
          >
            {data.numberPmidsWithChemsFiltered}{' '}
          </button>
        </>
      );
    },
  },
  {
    name: 'numberPmidsWithChemsIncludedInEvidence',
    sortable: true,
    header: <span title="Number of Pmids With Chems Included in the Evidences">N PMIDs Chemicals</span>,
    headerProps: { style: Color },
    minWidth: 180,
    render: ({ data }) => {
      const { view, tab, setMappingContent } = searchContext();

      let mappingItems = [];
      let columVisibilityModelKey = '';
      let tabSegment = '';
      if (tab === 'GDA') {
        columVisibilityModelKey = 'numberPmidsWithChemsIncludedInEvidenceGDA';
        tabSegment = 'gda';
        mappingItems = [
          {
            filterParam: 'disease',
            filterValue: data.diseaseUMLSCUI, // 'C0028754'
            name: data.diseaseName, // 'Obesity'
            id: data.diseaseUMLSCUI, // 'C0028754'
          },
          {
            filterParam: 'gene_ncbi_id',
            filterValue: data.geneNcbiID, // '5443'
            name: data.geneSymbol, // 'POMC'
            id: data.geneDescription, // 'proopiomelanocortin'
          },
          {
            filterParam: 'chem_cuis_init',
            filterValue: 'ALL',
            // name: data.chemShowName, // metformin
            // id: data.chemUMLSCUI, // C0025598
          },
        ];
      } else if (tab === 'VDA') {
        columVisibilityModelKey = 'numberPmidsWithChemsIncludedInEvidenceVDA';
        tabSegment = 'vda';
        mappingItems = [
          {
            filterParam: 'disease',
            filterValue: data.diseaseUMLSCUI, // 'C0028754'
            name: data.diseaseName, // 'Obesity'
            id: data.diseaseUMLSCUI, // 'C0028754'
          },
          {
            filterParam: 'variant',
            filterValue: data.variantStrID, // 'rs4994'
            name: data.variantStrID, // 'rs4994'
            id: data.geneSymbol, // 'ADRB3'
          },
          {
            filterParam: 'chem_cuis_init',
            filterValue: 'ALL',
            // name: data.chemShowName, // metformin
            // id: data.chemUMLSCUI, // C0025598
          },
        ];
      } else {
        // console.error('Edge case not handled');
      }

      return (
        <>
          <button
            className="tab-links"
            onClick={() =>
              setMappingContent(prevContent => ({
                name: 'numberPmidsWithChemsIncludedInEvidence',
                isActive: true,
                tabSegment: tabSegment,
                viewSegment: '',
                mappingItems: mappingItems,
                columVisibilityModelKey: columVisibilityModelKey,
              }))
            }
          >
            {data.numberPmidsWithChemsIncludedInEvidence}{' '}
          </button>
        </>
      );
    },
  },
  {
    name: 'numDBSNPsupportingAssociation',
    sortable: true,
    header: (
      <span title="Number of Variants for the Gene-Disease Association">
        N variants<sub>gda</sub>
      </span>
    ),
    headerProps: { style: Color },
    minWidth: 130,
    render: ({ data }) => {
      const { view, tab, setMappingContent } = searchContext();

      let mappingItems = [];
      let columVisibilityModelKey = '';
      if (tab === 'GDA') {
        columVisibilityModelKey = 'GENES_VDA'; // renders both summary and evidence
        mappingItems = [
          {
            filterParam: 'disease',
            filterValue: data.diseaseUMLSCUI, // 'C0028754'
            name: data.diseaseName, // 'Obesity'
            id: data.diseaseUMLSCUI, // 'C0028754'
          },
          {
            filterParam: 'gene_ncbi_id',
            filterValue: data.geneNcbiID, // '5443'
            name: data.geneSymbol, // 'POMC'
            id: data.geneDescription, // 'proopiomelanocortin'
          },
        ];
      } else {
        // console.error('Edge case not handled');
      }
      return (
        <>
          <button
            className="tab-links"
            onClick={() =>
              setMappingContent(prevContent => ({
                name: 'numDBSNPsupportingAssociation',
                isActive: true,
                tabSegment: 'vda',
                viewSegment: '',
                mappingItems: mappingItems,
                columVisibilityModelKey: columVisibilityModelKey,
              }))
            }
          >
            {data.numDBSNPsupportingAssociation}{' '}
          </button>
        </>
      );
    },
  },
  {
    name: 'numDiseasesAssociatedToGene',
    sortable: true,
    header: (
      <span title="Number of diseases associated to the gene">
        N diseases<sub>g</sub>
      </span>
    ),
    headerProps: { style: Color },
    minWidth: 130,
    render: ({ data }) => {
      const { view, tab } = searchContext();

      let newUrl = '';
      let newParams = '';
      let location = useLocation();
      if (tab === 'GENES') {
        // This navigates from the GENES browse view
        // to a GDA pivoted around selected gene
        newParams = updateParams({ view: DATASETS.GENES, tab: 'GDA', searchItems: [{ id: data.ncbiID }] });
      } else if (tab === 'GDA') {
        // This navigates from a GDA pivot around a disease or chem
        // or GDA browse view
        // to a GDA pivot on the selected gene
        newParams = updateParams({
          view: DATASETS.GENES,
          tab: 'GDA',
          searchItems: [{ id: data.geneNcbiID }],
        });
      } else {
        // console.error('Edge case not handled');
      }
      newUrl = `${location.pathname}?${newParams}`;

      return (
        <Link className="tab-links" to={newUrl}>
          {data.numDiseasesAssociatedToGene}{' '}
        </Link>
      );
    },
  },
  {
    //todoo
    name: 'numDiseasesAssociatedToVariant',
    sortable: true,
    header: (
      <span title="Number of diseases associated to the variant">
        N diseases<sub>v</sub>
      </span>
    ),
    headerProps: { style: Color },
    minWidth: 115,
    render: ({ data }) => {
      const { tab } = searchContext();
      const location = useLocation();
      let newParams = '';

      const createNewUrl = params => `${location.pathname}?${params}`;

      try {
        if (tab === 'VARIANTS') {
          newParams = updateParams({
            view: DATASETS.VARIANTS,
            tab: 'VDA',
            searchItems: [{ id: data.strID }],
          });
        } else if (tab === 'VDA') {
          newParams = updateParams({
            view: DATASETS.VARIANTS,
            tab: 'VDA',
            searchItems: [{ id: data.variantStrID }],
          });
        } else {
          // console.error('Edge case not handled');
          return null;
        }
        const newUrl = createNewUrl(newParams);
        return (
          <Link className="tab-links" to={newUrl}>
            {data.numDiseasesAssociatedToVariant}{' '}
          </Link>
        );
      } catch (error) {
        console.error('Error updating URL parameters', error);
        return null;
      }
    },
  },
  {
    name: 'numGenesAssociatedToDisease',
    sortable: true,
    minWidth: 105,
    header: (
      <span title="Number of genes associated to the disease">
        N genes<sub>d</sub>
      </span>
    ),
    headerProps: { style: Color },
    render: ({ data }) => {
      const { view, tab } = searchContext();

      let newUrl = '';
      let newParams = '';
      let location = useLocation();
      if (tab === 'DISEASES') {
        // This navigates from the DISEASES browse view
        // to a GDA pivoted on the selected DISEASE
        newParams = updateParams({
          view: DATASETS.DISEASES,
          tab: 'GDA',
          searchItems: [{ id: data.diseaseUMLSCUI }],
        });
      } else if (tab === 'GDA') {
        // This navigates from a GDA pivot
        // to a GDA pivot on the selected Disease
        newParams = updateParams({
          view: DATASETS.DISEASES,
          tab: 'GDA',
          searchItems: [{ id: data.diseaseUMLSCUI }],
        });
      } else {
        // console.error('Edge case not handled');
      }
      newUrl = `${location.pathname}?${newParams}`;

      return (
        <Link className="tab-links" to={newUrl}>
          {data.numGenesAssociatedToDisease}{' '}
        </Link>
      );
    },
  },
  //fran
  {
    name: 'numPMIDsupportingAssociation',
    sortable: true,
    header: <span title="Number of Publications supporting the association">N PMIDs</span>,
    headerProps: { style: Color },
    minWidth: 130,
    render: ({ data }) => {
      const { view, tab, setMappingContent } = searchContext();
      let mappingItems = [];
      let columVisibilityModelKey = '';
      let tabSegment = '';
      // todo: not entirely sure about the logic here
      // breaks under redirects from chemNumGdas submapping ie the chemNumGdas redirects that arent chem all
      // so i added the data?.chemsIncludedInEvidenceBySource to redirect the logic flow
      if (view === 'CHEMICALS' && tab === 'GDA' && data?.chemsIncludedInEvidenceBySource) {
        columVisibilityModelKey = 'numPMIDsupportingAssociationGDA';
        tabSegment = 'gda';
        mappingItems = [
          {
            filterParam: 'disease',
            filterValue: data.diseaseUMLSCUI, // 'C0028754'
            name: data.diseaseName, // 'Obesity'
            id: data.diseaseUMLSCUI, // 'C0028754'
          },
          {
            filterParam: 'gene_ncbi_id',
            filterValue: data.geneNcbiID, // '5443'
            name: data.geneSymbol, // 'POMC'
            id: data.geneDescription, // 'proopiomelanocortin'
          },
          {
            filterParam: 'chem_cuis_init',
            filterValue: data?.chemsIncludedInEvidenceBySource[0]?.chemUMLSCUI, // C0025598
            name: data?.chemsIncludedInEvidenceBySource[0]?.chemShowName, // metformin
            id: data?.chemsIncludedInEvidenceBySource[0]?.chemUMLSCUI, // C0025598
          },
        ];
      } else if (tab === 'GDA') {
        columVisibilityModelKey = 'numPMIDsupportingAssociationGDA';
        tabSegment = 'gda';
        mappingItems = [
          {
            filterParam: 'disease',
            filterValue: data.diseaseUMLSCUI, // 'C0028754'
            name: data.diseaseName, // 'Obesity'
            id: data.diseaseUMLSCUI, // 'C0028754'
          },
          {
            filterParam: 'gene_ncbi_id',
            filterValue: data.geneNcbiID, // '5443'
            name: data.geneSymbol, // 'POMC'
            id: data.geneDescription, // 'proopiomelanocortin'
          },
        ];
      } else if (tab === 'VDA') {
        columVisibilityModelKey = 'numPMIDsupportingAssociationVDA';
        tabSegment = 'vda';
        mappingItems = [
          {
            filterParam: 'disease',
            filterValue: data.diseaseUMLSCUI, // 'C0028754'
            name: data.diseaseName, // 'Obesity'
            id: data.diseaseUMLSCUI, // 'C0028754'
          },
          {
            filterParam: 'variant',
            filterValue: data.variantStrID, // 'rs4994'
            name: data.variantStrID, // 'rs4994'
            id: data.geneSymbol, // 'ADRB3'
          },
        ];
      } else {
        // console.error('Edge case not handled');
      }

      return (
        <>
          <button
            className="tab-links"
            onClick={() =>
              setMappingContent(prevContent => ({
                name: 'numPMIDsupportingAssociation',
                isActive: true,
                tabSegment: tabSegment,
                viewSegment: '',
                mappingItems: mappingItems,
                columVisibilityModelKey: columVisibilityModelKey,
              }))
            }
          >
            {data.numPMIDsupportingAssociation}{' '}
          </button>
        </>
      );
    },
  },
  {
    name: 'numPublications',
    sortable: true,
    header: <span title="Number of Publications mentioning the disease">N PMIDs</span>,
    headerProps: { style: Color },
    render: ({ data }) => {
      const { view, tab } = searchContext();

      let newUrl = '';
      let newParams = '';
      let location = useLocation();
      if (tab === 'GENES') {
        // Navigates from Genes browse view
        // to GDA pivot on the selected gene
        newParams = updateParams({ view: DATASETS.GENES, tab: 'GDA', searchItems: [{ id: data.ncbiID }] });
      } else if (tab === 'VARIANTS') {
        // Navigates from Variants browse view
        // to VDA pivot on the selected Variant
        newParams = updateParams({
          view: DATASETS.VARIANTS,
          tab: 'VDA',
          searchItems: [{ id: data.strID }],
        });
      } else if (tab === 'DISEASES') {
        // Diseases browse view doesn't have a link to an association pivot
        return data.numPublications;
      } else {
        // console.error('Edge case not handled');
      }
      newUrl = `${location.pathname}?${newParams}`;

      return (
        <Link className="tab-links" to={newUrl}>
          {data.numPublications}{' '}
        </Link>
      );
    },
  },
  {
    name: 'numPublications_GDA',
    sortable: true,
    header: <span title="Number of Publications mentioning the disease-gene association">N PMIDs GDA</span>,
    headerProps: { style: Color },
    render: ({ data }) => {
      const { view, tab } = searchContext();

      let newUrl = '';
      let newParams = '';
      let location = useLocation();
      if (tab === 'DISEASES') {
        // Navigates from Diseases browse view
        // to GDA pivot on the selected disease
        newParams = updateParams({
          view: DATASETS.DISEASES,
          tab: 'GDA',
          searchItems: [{ id: data.diseaseUMLSCUI }],
        });
      } else {
        // console.error('Edge case not handled');
      }
      newUrl = `${location.pathname}?${newParams}`;

      return (
        <Link className="tab-links" to={newUrl}>
          {data.numPublications_GDA}{' '}
        </Link>
      );
    },
  },
  {
    name: 'numPublications_VDA',
    sortable: true,
    header: (
      <span title="Number of Publications mentioning the disease-variant association">N PMIDs VDA</span>
    ),
    headerProps: { style: Color },
    render: ({ data }) => {
      const { view, tab } = searchContext();

      let newUrl = '';
      let newParams = '';
      let location = useLocation();
      if (tab === 'DISEASES') {
        // Navigates from Diseases browse view
        // to VDA pivot on the selected disease
        newParams = updateParams({
          view: DATASETS.DISEASES,
          tab: 'VDA',
          searchItems: [{ id: data.diseaseUMLSCUI }],
        });
      } else {
        // console.error('Edge case not handled');
      }
      newUrl = `${location.pathname}?${newParams}`;

      return (
        <Link className="tab-links" to={newUrl}>
          {data.numPublications_VDA}{' '}
        </Link>
      );
    },
  },
  {
    name: 'numVariantsAssociatedToDisease',
    sortable: true,
    minWidth: 125,
    header: (
      <span title="Number of variants associated to the disease">
        N variants<sub>d</sub>
      </span>
    ),
    headerProps: { style: Color },
    render: ({ data }) => {
      const { view, tab } = searchContext();

      let newUrl = '';
      let newParams = '';
      let location = useLocation();
      if (tab === 'DISEASES') {
        // Navigates from Disease browse view
        // to VDA pivot on the selected disease
        newParams = updateParams({
          view: DATASETS.DISEASES,
          tab: 'VDA',
          searchItems: [{ id: data.diseaseUMLSCUI }],
        });
      } else if (tab === 'GDA') {
        // Navigates from GDA based views
        // to VDA pivot on the selected Disease
        newParams = updateParams({
          view: DATASETS.DISEASES,
          tab: 'VDA',
          searchItems: [{ id: data.diseaseUMLSCUI }],
        });
      } else {
        // console.error('Edge case not handled');
      }
      newUrl = `${location.pathname}?${newParams}`;

      return (
        <Link className="tab-links" to={newUrl}>
          {data.numVariantsAssociatedToDisease}{' '}
        </Link>
      );
    },
  },
  {
    name: 'numVariantsAssociatedToGene',
    sortable: true,
    header: (
      <span title="Number of variants associated to the gene">
        N variants<sub>g</sub>
      </span>
    ),
    headerProps: { style: Color },
    minWidth: 130,
    render: ({ data }) => {
      const { view, tab } = searchContext();

      let newUrl = '';
      let newParams = '';
      let location = useLocation();
      if (tab === 'GENES') {
        // This navigates from the GENES browse view
        // to a VDA pivoted around selected gene
        newParams = updateParams({ view: DATASETS.GENES, tab: 'VDA', searchItems: [{ id: data.ncbiID }] });
      } else if (tab === 'GDA') {
        // This navigates from a GDA pivot around a disease or chem
        // to a VDA pivot on the selected gene
        newParams = updateParams({
          view: DATASETS.GENES,
          tab: 'VDA',
          searchItems: [{ id: data.geneNcbiID }],
        });
      } else {
        // console.error('Edge case not handled');
      }
      newUrl = `${location.pathname}?${newParams}`;

      return (
        <Link className="tab-links" to={newUrl}>
          {data.numVariantsAssociatedToGene}{' '}
        </Link>
      );
    },
  },
  {
    name: 'nvariants_diseaseID_1',
    sortable: true,
    header: (
      <span title="Number of variants of the index disease">
        {' '}
        N variants <sub>d1</sub>{' '}
      </span>
    ),
    headerProps: { style: Color },
    minWidth: 125,
    render: ({ data }) => {
      const { view, tab, setMappingContent } = searchContext();

      let mappingItems = [];
      let columVisibilityModelKey = '';
      let tabSegment = '';
      if (tab === 'DDA') {
        columVisibilityModelKey = 'nvariants_diseaseID';
        tabSegment = 'vda';
        mappingItems = [
          {
            filterParam: 'disease',
            filterValue: data.disease1_UMLSCUI, // 'C0028754'
            name: data.disease1_Name, // 'Obesity'
            id: data.disease1_UMLSCUI, // 'C0028754'
          },
        ];
      } else {
        // console.error('Edge case not handled');
      }

      return (
        <>
          <button
            className="tab-links"
            onClick={() =>
              setMappingContent(prevContent => ({
                name: 'nvariants_diseaseID_1',
                isActive: true,
                tabSegment: tabSegment,
                viewSegment: '',
                mappingItems: mappingItems,
                columVisibilityModelKey: columVisibilityModelKey,
              }))
            }
          >
            {data.nvariants_diseaseID_1}{' '}
          </button>
        </>
      );
    },
  },
  {
    name: 'nvariants_diseaseID_2',
    sortable: true,
    header: (
      <span title="Number of variants of the associated disease">
        N Variants <sub>d2</sub>
      </span>
    ),
    headerProps: { style: Color },
    minWidth: 125,
    render: ({ data }) => {
      const { view, tab, setMappingContent } = searchContext();

      let mappingItems = [];
      let columVisibilityModelKey = '';
      let tabSegment = '';
      if (tab === 'DDA') {
        columVisibilityModelKey = 'nvariants_diseaseID';
        tabSegment = 'vda';
        mappingItems = [
          {
            filterParam: 'disease',
            filterValue: data.disease2_UMLSCUI, // 'C0028754'
            name: data.disease2_Name, // 'Obesity'
            id: data.disease2_UMLSCUI, // 'C0028754'
          },
        ];
      } else {
        // console.error('Edge case not handled');
      }

      return (
        <>
          <button
            className="tab-links"
            onClick={() =>
              setMappingContent(prevContent => ({
                name: 'nvariants_diseaseID_2',
                isActive: true,
                tabSegment: tabSegment,
                viewSegment: '',
                mappingItems: mappingItems,
                columVisibilityModelKey: columVisibilityModelKey,
              }))
            }
          >
            {data.nvariants_diseaseID_2}{' '}
          </button>
        </>
      );
    },
  },
  {
    name: 'or_or_beta',
    sortable: true,
    header: (
      <>
        <span title="Reported odds ratio or beta-coefficient">OR or Beta</span>{' '}
        <OpenPopup popupType="attributes" />
      </>
    ),
    headerProps: { style: Color },
    minWidth: 125,
    render: instance => renderDecimalValues(instance.data['or_or_beta']),
  },
  {
    name: 'pathwayID',
    sortable: true,
    header: <span title="Pathways IDs">Pathways IDs</span>,
    render: instance => renderListsFromObjects(instance, 'pathways', 'pathwayID'),
    headerProps: { style: Color },
    minWidth: 295,
  },
  {
    name: 'pathwayName',
    sortable: true,
    header: <span title="Pathways Names">Pathways</span>,
    render: instance => renderListsFromObjects(instance, 'pathways', 'pathwayName'),
    headerProps: { style: Color },
    minWidth: 295,
  },
  {
    name: 'reference',
    sortable: true,
    header: <span title="reference type">Reference</span>,
    headerProps: { style: Color },
    render: ({ data }) => {
      const reference = data.reference;
      const referenceType = data.reference_type
      let linkout = '';
      if (referenceType === 'PMID' && reference) {
        linkout = variantLink(reference);
      } else if (referenceType === 'NCTID' && reference) {
        linkout = getNctidLink(reference);
      }

      return <React.Fragment>{linkout}</React.Fragment>;
    },
  },
  {
    name: 'reference_type',
    sortable: true,
    header: <span title="reference type">Reference Type</span>,
    headerProps: { style: Color },

    filterEditor: SelectFilter,
    filterEditorProps: props => {
      const dropdown = dropdownLists.reference_type;
      return {
        dataSource: dropdown?.map(c => {
          return { id: c, label: c };
        }),
        constrainTo: '.InovuaReactDataGrid__body',
      };
    },
  },
  {
    name: 'PLI',
    sortable: true,
    header: (
      <>
        <span title="Probability of being loss of function intolerant">pLI</span>
        <OpenPopup popupType="attributes" />
      </>
    ),
    headerProps: { style: Color },
    minWidth: 85,
    render: instance => renderRoundedValues(instance, 'PLI'),
  },
  //fran
  {
    name: 'pmid',
    sortable: true,
    header: (
      <>
        <span title="PubMed Identifier">PMID</span> <OpenPopup popupType="attributes" />
      </>
    ),
    headerProps: { style: Color },
    minWidth: 125,
    render: row => {
      const pmid = row.data.pmid;
      const linkout = pmid ? getPmidLink(pmid) : '';
      return <React.Fragment>{linkout}</React.Fragment>;
    },
  },
  {
    name: 'pmYear',
    sortable: true,
    header: <span title="Publication Year">Reference Year</span>,
    headerProps: { style: Color },
    minWidth: 158,
  },
  {
    name: 'proteinClassIDs',
    sortable: true,
    header: (
      <>
        <span title="Protein classification according to the DrugTarget Ontology">Protein Class</span>
        <OpenPopup popupType="attributes" />
      </>
    ),
    filterEditor: SelectFilter,
    filterEditorProps: {
      dataSource: dropdownLists.proteinClassIDs?.map(c => ({
        id: c,
        label: c.replace(/\b\w/g, match => match.toUpperCase()),
      })),
      constrainTo: '.InovuaReactDataGrid__body',
    },
    headerProps: { style: Color },
    minWidth: 170,
    render: instance => {
      let proteinClassIDs = instance.data.proteins;
      let storedClasses = [];
      let listProteins = proteinClassIDs?.map(item => {
        let elList = [];
        item.proteinClasses.forEach((protClass, ix, list2) => {
          if (!storedClasses.includes(protClass.name)) {
            storedClasses.push(protClass.name);
            let el;
            if (list2?.length - 1 !== ix && storedClasses?.length > 1) {
              el = <span>{protClass.name} | </span>;
            } else {
              el = <span>{protClass.name}</span>;
            }
            elList.push(el);
          }
        });
        return elList;
      });
      return listProteins;
    },
  },
  {
    name: 'proteinIDs', // todo: remap to geneProteinStrIDs
    sortable: true,
    header: (
      <>
        <span title="UniProt Accession">UniProt</span> <OpenPopup popupType="attributes" />
      </>
    ),
    headerProps: { style: Color },
    render: instance => {
      let arr_uniprot = [];
      let instance_length = instance.data.proteinIDs?.length;

      for (let i = 0; i < instance_length; i++) {
        let uniprot = instance.data.proteinIDs[i];
        let link = `https://www.uniprot.org/uniprot/${uniprot}`;
        if (i !== instance_length - 1) {
          arr_uniprot.push(
            <span>
              <a href={link} target="_blank" rel="noopener noreferrer">
                {uniprot} <i className="fa fa-external-link" aria-hidden="true"></i>
              </a>{' '}
              |{' '}
            </span>,
          );
        } else {
          arr_uniprot.push(
            <span>
              <a href={link} target="_blank" rel="noopener noreferrer">
                {uniprot} <i className="fa fa-external-link" aria-hidden="true"></i>
              </a>
            </span>,
          );
        }
      }

      return arr_uniprot;
    },
    minWidth: 150,
  },
  {
    name: 'pValue',
    sortable: true,
    header: (
      <>
        <span title="P-value of the GWAS">pValue</span> <OpenPopup popupType="attributes" />
      </>
    ),
    headerProps: { style: Color },
    minWidth: 95,
  },
  {
    name: 'pvalue_jaccard_genes',
    sortable: true,
    header: (
      <span title="log10 pValue of the jaccard genes">
        -log<sub>10</sub>(pValue JI<sub>g</sub>)
      </span>
    ),
    headerProps: { style: Color },
    minWidth: 170,
    render: row => {
      const dado = row.data.pvalue_jaccard_genes;
      const pvalue = parseFloat(dado).toFixed(2);
      return <span>{pvalue}</span>;
    },
  },
  {
    name: 'pvalue_jaccard_variants',
    sortable: true,
    header: (
      <span title="log10 pValue of the jaccard variants">
        -log<sub>10</sub>(pValue JI<sub>v</sub>)
      </span>
    ),
    headerProps: { style: Color },
    minWidth: 170,
    render: row => {
      const dado = row.data.pvalue_jaccard_variants;
      const pvalue = parseFloat(dado).toFixed(2);
      return <span>{pvalue}</span>;
    },
  },
  {
    name: 'riskAllele',
    sortable: true,
    header: (
      <>
        <span title="Risk Allele">Risk Allele</span> <OpenPopup popupType="attributes" />
      </>
    ),
    headerProps: { style: Color },
    minWidth: 125,
    filterEditor: SelectFilter,
    filterEditorProps: {
      dataSource: dropdownLists.riskAllele?.map(c => {
        return { id: c, label: c };
      }),
    },
  },
  {
    name: 'score',
    sortable: true,
    header: (
      <>
        <ScoreHeader />
      </>
    ),
    headerProps: { style: Color },
    filterEditorProps: {
      minValue: 0,
      maxValue: 1,
      step: 0.01,
    },
    minWidth: 115,
  },
  {
    name: 'sentenceHTML',
    sortable: false,
    header: <span title="Sentence supporting the association">Sentence</span>,
    textEllipsis: true,
    render: instance => renderSentenceHTML(instance),
    headerProps: { style: Color },
    minWidth: 520,
    defaultFlex: 1,
  },
  {
    name: 'shared_genes',
    sortable: true,
    header: <span title="Number of shared genes between both diseases">N shared Genes</span>,
    headerProps: { style: Color },
    render: ({ data }) => {
      const { view, tab } = searchContext();

      let mappingItems = [];
      let columVisibilityModelKey = '';
      let tabSegment = '';
      if (tab === 'DDA') {
        columVisibilityModelKey = 'ngenes_diseaseID';
        tabSegment = 'gda/shared';
        mappingItems = [
          {
            filterParam: 'disease',
            filterValue: data.disease1_UMLSCUI, // 'C0028754'
            // filterValue: [data.disease1_UMLSCUI, data.disease2_UMLSCUI].join(','), // For "shared" endpoints that need a list, separate items allow for info to render correctly, while filter value should be hardcoded in final item
            name: data.disease1_Name, // 'Obesity'
            id: data.disease1_UMLSCUI, // 'C0028754'
          },
          {
            filterParam: 'disease',
            filterValue: data.disease2_UMLSCUI, // 'C0028754'
            // filterValue: [data.disease1_UMLSCUI, data.disease2_UMLSCUI].join(','), // For "shared" endpoints that need a list, separate items allow for info to render correctly, while filter value should be hardcoded in final item
            name: data.disease2_Name, // 'Obesity'
            id: data.disease2_UMLSCUI, // 'C0028754'
          },
        ];
      } else {
        // console.error('Edge case not handled');
      }
      return <>{data.shared_genes} </>;
    },
  },
  {
    name: 'shared_variants',
    sortable: true,
    header: <span title="Number of shared variants between both diseases">N shared Variants</span>,
    headerProps: { style: Color },
    render: ({ data }) => {
      const { view, tab, setMappingContent } = searchContext();

      let mappingItems = [];
      let columVisibilityModelKey = '';
      let tabSegment = '';
      if (tab === 'DDA') {
        columVisibilityModelKey = 'nvariants_diseaseID';
        tabSegment = 'vda/shared';
        mappingItems = [
          {
            filterParam: 'disease',
            filterValue: data.disease1_UMLSCUI, // 'C0028754'
            // filterValue: [data.disease1_UMLSCUI, data.disease2_UMLSCUI].join(','), // For "shared" endpoints that need a list, separate items allow for info to render correctly, while filter value should be hardcoded in final item
            name: data.disease1_Name, // 'Obesity'
            id: data.disease1_UMLSCUI, // 'C0028754'
          },
          {
            filterParam: 'disease',
            filterValue: data.disease2_UMLSCUI, // 'C0028754'
            // filterValue: [data.disease1_UMLSCUI, data.disease2_UMLSCUI].join(','), // For "shared" endpoints that need a list, separate items allow for info to render correctly, while filter value should be hardcoded in final item
            name: data.disease2_Name, // 'Obesity'
            id: data.disease2_UMLSCUI, // 'C0028754'
          },
        ];
      } else {
        // console.error('Edge case not handled');
      }

      return <>{data.shared_variants} </>;
    },
  },
  {
    name: 'sokal',
    sortable: true,
    header: <span title="SOKAL diseases' similarity">SOKAL</span>,
    headerProps: { style: Color },
    render: instance => renderRoundedValues(instance, 'sokal'),
  },
  {
    name: 'source',
    sortable: true,
    header: (
      <>
        <span title="Database reporting the association">Original DB</span>{' '}
        <OpenPopup popupType="attributes" />
      </>
    ),
    filterEditor: SelectFilter,
    filterEditorProps: {
      dataSource: dropdownLists.source?.map(c => ({ id: c, label: c })),
      constrainTo: '.InovuaReactDataGrid__body',
    },
    headerProps: { style: Color },
    minWidth: 130,
  },
  {
    name: 'strID',
    sortable: true,
    header: <span title="Variant">Variant</span>,
    headerProps: { style: Color },
    minWidth: 130,
    render: row => {
      const variant = row.data.strID;
      const linkout = variant ? variantLink(variant) : '';
      return <React.Fragment>{linkout}</React.Fragment>;
    },
  },
  {
    name: 'type',
    sortable: true,
    header: (
      <>
        <span title="Disease Type">Disease type</span>
        <OpenPopup popupType="attributes" />
      </>
    ),
    filterEditor: SelectFilter,
    filterEditorProps: {
      dataSource: dropdownLists.type?.map(c => ({
        id: c,
        label: c.replace(/\b\w/g, match => match.toUpperCase()),
      })),
      constrainTo: '.InovuaReactDataGrid__body',
    },
    headerProps: { style: Color },
  },
  {
    name: 'variantDPI',
    sortable: true,
    header: (
      <>
        <span title="Disease Pleiotropy Index for the variant">
          DPI<sub>v</sub>
        </span>
        <OpenPopup popupType="metrics" />
      </>
    ),
    headerProps: { style: Color },
  },
  {
    name: 'variantDSI',
    sortable: true,
    header: (
      <>
        <span title="Disease Specificity Index for the variant">
          DSI<sub>v</sub>
        </span>
        <OpenPopup popupType="metrics" />
      </>
    ),
    headerProps: { style: Color },
  },
  {
    name: 'variantStrID',
    sortable: true,
    header: <span title="Variant">Variant</span>,
    headerProps: { style: Color },
    render: instance => renderVariantLink(instance),
  },
  {
    name: 'yearFinal',
    sortable: true,
    header: (
      <>
        <span title="Last time the association was reported">Last Ref.</span>
        <OpenPopup popupType="attributes" />
      </>
    ),
    headerProps: { style: Color },
    minWidth: 115,
  },
  {
    name: 'yearInitial',
    sortable: true,
    header: (
      <>
        <span title="First time the disease was reported">First Ref.</span>
        <OpenPopup popupType="attributes" />
      </>
    ),
    headerProps: { style: Color },
    minWidth: 115,
  },
  {
    name: 'chemical_effect',
    sortable: true,
    header: <span>Chemical Effect</span>,
    headerProps: { style: Color },
    columnSize: 10,
    render: data => {
      const value = data.value || '';
      const formattedValue = value.replace(/\|/g, ' | ');
      return formattedValue;
    },
  },
];

// Filters.forEach(filter => {
//   const columnToUpdate = Columns.find(col => col.name === filter.name);
//   if (columnToUpdate) {
//     columnToUpdate.filter = true;
//     columnToUpdate.operator = [filter.operator];
//     columnToUpdate.type = filter.type;
//     columnToUpdate.filterRenderer = ({ column, onChange }) => (
//       <input
//         type={filter.type === 'string' ? 'text' : 'number'}
//         placeholder={`Filter ${column.name}`}
//         onChange={e => onChange(e.target.value)}
//         style={{ width: '100%', boxSizing: 'border-box' }}
//       />
//     );
//   }
// });

export { Columns, ButtonInfoGeneContent };
