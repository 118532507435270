import React from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

function Biomarkers() {
  const location = useLocation();
  const { hash, pathname } = location;
  const commonClassNames = 'mb-1 dropdown-item text-wrap';

  return (
    <section>
      <div className="container pt-4 d-flex">
        <div
          className={`tab-pane ${hash === '#Biomarkers' || (!hash && pathname === '/Tools') ? 'active show' : ''}`}
          id="Biomarkers"
        >
          <h2>Biomarkers</h2>
          <h6 className="p-t4">
            "The genomic and proteomic biomarkers in clinical trials described in Piñero et al. Comput Struct
            Biotechnol J. 2023 Mar 16;21:2110-2118. doi: 10.1016/j.csbj.2023.03.014. PMID: 36968019 have been
            integrated into DISGENET"
          </h6>
          <div style={{ paddingBottom: '320px' }}></div>
        </div>
      </div>
    </section>
  );
}

export default Biomarkers;
