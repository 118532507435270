import React from 'react';
import { Link } from 'react-router-dom';

function SummaryBlog() {
  return (
    <section>
      <h2 className="mt-3">
        <Link className="gradient-color" to="/blog">
          Blog
        </Link>
      </h2>
      <Link to="/blog/The-Key-To-Clinical-Trial-Success" className="summary-list text-wrap">
        Genetics In Drug Discovery: The Key To Clinical Trial Success?
      </Link>
      <Link to="/blog/disgenet-academic-license-guide" className="summary-list text-wrap">
        DISGENET Academic License: Eligibility, Application & Free Access
      </Link>
      <Link to="/blog/disgenet-nlp-to-accelerate-drug-development" className="summary-list text-wrap">
        DISGENET NLP to Accelerate Drug Development
      </Link>
      <Link to="/blog/The-Next-Chapter-For-Disease-Genomics-Research" className="summary-list text-wrap">
        DISGENET: The Next Chapter For Disease Genomics Research
      </Link>
    </section>
  );
}

export default SummaryBlog;
